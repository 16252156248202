<template>
    <div class="tos-root text-page">
        <div class="center"><b class="title">USLOVI KORIŠTENJA</b><br/></div>

        <b>1.1. Uvod</b><br/>
        <p class="text-page-text">
            Gravis društvo za odnose sa javnošću d.o.o. Sarajevo (u daljem tekstu: Gravis d.o.o.) je vlasnik i lice koje
            upravlja sajtom i aplikacijom Tvornica snova (u daljem tekstu: Tvornica snova), koja omogućava korisnicima
            da kupuju elektronske knjige (e-knjige), da ih čitaju u online i offline režimu, da ih čuvaju u
            svojoj biblioteci, komentarišu, ocjenjuju i dijele na društvenim mrežama. Aplikacija također omogućava
            korisnicima kreiranje korisničkog profila.
        </p>
        <b>O nama:</b><br/>
        <b>Puni naziv pravnog subjekta:</b> Gravis društvo za odnose sa javnošću d.o.o. Sarajevo<br/>
        <b>Sjedište:</b> Hamze Orlovića 8, Sarajevo<br/>
        <b>Sudska registracija:</b> MBS: 65-01-0220-21<br/>
        <b>Identifikacijski broj:</b> 4202867330000<br/>
        <b>Kontakt e-mail:</b> <a href="mailto:info@tvornicasnova.com">info@tvornicasnova.com</a><br/>
        <b>Web adresa:</b> <a href="www.tvornicasnova.com" target="_blank">www.tvornicasnova.com</a><br/><br/>
        <b>1.2. Prihvatanje uslova</b><br/>
        <p class="text-page-text">Ovi Uslovi korištenja, uključujući i Politiku privatnosti, predstavljaju sporazum između korisnika i Gravis
            d.o.o., u pogledu usluga koje Tvornica snova pruža korisnicima. Pristupanjem i/ ili korištenjem Tvornice
            snova, korisnik prihvata i Uslove korištenja, uključujući i Politiku privatnosti, korisnik potvrđuje da ima
            18 godina i da je saglasan da Gravis d.o.o. koristi u svemu u skladu sa navedenim uslovima, kao i da je
            saglasan da njegovi lični podaci, koji su potrebni i zahtijevani u cilju pružanja usluga, budu korišteni u
            skladu sa Politikom privatnosti. Ukoliko korisnik nije saglasan sa Uslovima korištenja i/ ili Politikom
            privatnosti, ne treba da nastavi sa korištenjem Tvornice snova.</p>
        <b>1.3. Usluge dostupne na Tvornica snova aplikaciji/ sajtu</b><br/>
        <p class="text-page-text">Kupovina e-knjige se ostvaruje direktno na profilu odabranog naslova i naplaćuje se shodno izraženoj cijeni i
            dostupnim promo kodovima za popust. Kupovinom korisnik može da čita sva dostupna izdanja e-knjiga na
            servisu. Kupljeni naslovi ostaju trajno u biblioteci korisnika u offline režimu. Nije moguć prijenos
            kupljenih e-knjiga. Cijene pojedinačnih izdanja e-knjiga su jasno istaknute u odgovarajućoj valuti.
        </p>
        <b>1.4. Korisnički nalog</b><br/>
        <p class="text-page-text">Tvornica snova dostupna je za korištenje samo registrovanim korisnicima. Prilikom registracije korisnik
            obavezno unosi ime, prezime i e-mail, dok su neobavezni podaci godište, pol i mjesto u kojem korisnik živi.
            Registracija i login korisnika je moguć i povezivanjem sa drugim kreiranim nalozima na društvenim mrežama
            ili pak Google nalogom. Korisnici odgovaraju za tačnost unesenih podataka prilikom registracije, kao i za
            očuvanje tajnosti svoje lozinke. Strogo je zabranjeno korištenje tuđe e-mail adrese i lozinke bez dozvole
            vlasnika. U slučaju sumnje u zloupotrebu korisničkog naloga od strane trećih lica, korisnik je u obavezi da
            o tome odmah obavijesti Tvornicu snova putem e-maila na info@tvornicasnova.com. Tvornica snova zadržava
            diskreciono pravo da ugasi korisnički nalog ukoliko se posumnja u zloupotrebu naloga ili istinitost
            podataka.</p>
        <b>1.5. Način plaćanja</b><br/>
        <p class="text-page-text">Svi korisnici prilikom kupovine e-knjiga unutra Tvornice snova u ovom trenutku imaju na raspolaganju plaćanje
            platnom karticom. Kompanija Monri Payments d.o.o. (https://monri.com/) omogućava potrebni Internet Payment
            gateway u saradnji sa UniCredit bankom. Banka vrši provjeru Vaše kartice i raspoloživosti sredstava na
            računu i ako imate uslove za plaćanje, rezerviše sredstva na Vašem tekućem računu u vrijednosti iznosa
            potraživanih e-knjiga. Podaci sa platne kartice od Vas do sajta banke se prenose u zaštićenoj formi,
            upotrebom TLS protokola, kao trenutno najsavremenije tehnologije. Sigurnost podataka prilikom kupovine
            garantuje procesor platnih kartica. Niti jednog trenutka podaci o platnoj kartici nisu dostupni našem
            sistemu. Korisnik ne ostavlja podatke o kartici Tvornici snova. Cijeli sistem je organizovan u cilju
            maksimalne zaštite Vaših povjerljivih podataka i predstavlja standard za plaćanje preko Interneta širom
            sveta. Kada korisnik plati platnom karticom, Tvornica snova mu odmah isporučuju plaćene e-knjige. Ovaj
            sistem je automatizovan. Sva plaćanja biće izvršena u Euro valuti. Iznos za koji će biti zadužena Vaša
            platna kartica biće izražen u Vašoj lokalnoj valuti kroz konverziju u istu po kursu koji koriste kartičarske
            organizacije, a koji nama u trenutku transakcije ne može biti poznat. Kao rezultat ove konverzije postoji
            mogućnost neznatne razlike od originalne cijene navedene na našem sajtu. Hvala Vam na razumijevanju. PDV je
            uračunat u cijenu i nema skrivenih troškova.
        </p>
        <b>1.6. Zaštita poverljivih podataka o transakciji prilikom online kupovine</b><br/>
        <p class="text-page-text">Prilikom kupovine na Tvornici snova povjerljive informacija se prenose putem javne mreže u zaštićenoj
            (kriptovanoj) formi upotrebom TLS protokola i PKI sistema, kao trenutno najaktuelnije (state of the art)
            tehnologije. Sigurnost podataka prilikom kupovine garantuje procesor platnih kartica, pa se tako kompletni
            proces naplate obavlja van našeg sajta. Nijednog trenutka podaci o platnoj kartici nisu dostupni našem
            sistemu.</p>
        <b>1.7. Ponašanje korisnika</b><br/>
        <p class="text-page-text">Tvornica snova se može upotrebljavati samo u legalne svrhe i u skladu sa ovim uslovima.</p>
        <b>1.8. Međunarodni korisnici</b><br/>
        <p class="text-page-text">Tvornicom snova kontroliše, upravljaja i administrira Gravis d.o.o. iz svojih prostorija na teritoriji Bosne
            i Hercegovine. U slučaju da korisnik Tvornice snova pristupa sa lokacije koja se nalazi van Bosne i
            Hercegovine, odgovoran je za poštivanje lokalnih zakona države iz koje pristupa svom nalogu.</p>
        <b>1.9. Intelektualno vlasništvo</b><br/>
        <p class="text-page-text">
            Tvornica snova aplikacija i sajt su zaštićeni zakonima Bosne i Hercegovine i drugim međunarodnim propisima
            koji uređuju zaštitu prava intelektualne svojine. Cjelokupan sadržaj aplikacije/ sajta, odnosno tekst,
            grafika, logo i softver (uključujući računarske kodove i programe) koji se upotrebljava na ovoj aplikaciji/
            sajtu su u vlasništvu Gravis d.o.o., izuzev e-knjiga, koje su u vlasništvu saradnika, autora, izdavačkih
            kuća i drugih nosioca autorskih i/ ili elektroizdavačkih prava, osim ako nije jasno naglašeno da je autor
            ili izdavač Gravis d.o.o. Cjelokupan sadržaj aplikacije/ sajta predstavlja zaštićeni sadržaj. Sadržaj
            aplikacije ne obuhvata Korisnički sadržaj koji korisnik kreira, postavlja, objavljuje ili prenosi putem
            aplikacije Tvornica snova, kao ni sadržaj poruka i druge komunikacije koju korisnik upućuje drugim
            korisnicima ili aplikaciji Tvornica snova. Sva prava su zadržana. Korisnik ne stiče bilo kakva prava na
            Tvornica snova aplikaciji/ sajtu ili njenim dijelovima ili Sadržaju aplikacije/ sajta. Svako neovlašteno
            korištenje sadržaja Tvornice snova je zabranjeno i podliježe krivičnoj odgovornosti.
        </p>
        <b>1.10. Pristup sadržajima</b><br/>
        <p class="text-page-text">Glavni dio Tvornice snova aplikacije/ sajta predstavljaju e-knjige, podijeljene u više kategorija. Korisnik
            mora biti registrovan kako bi pristupio Tvornici snova, pretraživao i čitao e-knjige. Registracijom korisnik
            dobija mogućnost kupovine e-knjiga i čuvanja istih u svojoj elektronskoj biblioteci. E-knjige se mogu čitati
            samo na korisničkom profilu, odnosno u biblioteci, i ne mogu se preuzimati i razmjenjivati van biblioteke.
            Kada jednom kupi e-knjigu, ona ostaje u trajnom vlasništvu korisnika, sve dok je sam ne obriše iz svoje
            biblioteke ili deinstalira aplikaciju/ sajt. Kartičnim plaćanjem dostupnih naslova omogućava se korisniku da
            čita predmetna izdanja e-knjiga na servisu.</p>
        <b>1.11. Radovi na aplikaciji/ sajtu i ažuriranje servera</b><br/>
        <p class="text-page-text">Gravis d.o.o. zadržava pravo da aplikacija/ sajt povremeno bude nedostupna zbog dopune i modifikacije
            sadržaja i usluga aplikacije/ sajta, sa ili bez prethodnog obavještenja i uz isključenje bilo kakve
            odgovornosti po bilo kom osnovu s tim u vezi.</p>
        <b>1.12. Odricanje od odgovornosti</b><br/>
        <p class="text-page-text">
            Gravis d.o.o. ne daje bilo kakvu garanciju da će usluge ispuniti zahtjeve korisnika, da će usluge biti
            uvijek dostupne, da će biti tačne, sigurne ili besplatne, da ne postoji opasnost od računarskih virusa i
            drugih štetnih komponenti, da će dobijeni sadržaj biti tačan i pouzdan, ili kvalitet bilo kog proizvoda,
            usluga, informacija ili ostalog materijala koji je kupljen, nabavljen ili korišten preko Tvornice snova
            ispuniti bilo čija očekivanja. Samo korisnik snosi odgovornost za uspostavljanje i održavanje pouzdane
            Internet konekcije, odgovornost za plaćanje svih troškova uvezi sa Internet konekcijom i protokom podataka,
            kao i za svu opremu, servisiranje, popravke koji su neophodni kako bi se pristupilo Tvornici snova. Nikakav
            savjet ili usmena informacija koju je korisnik dobio preko ili od Tvornice snova ili kompanija sa kojima
            Gravis d.o.o. sarađuje, partnera i nosioca licenci i prava (ili direktora, službenika ili agenata navedenih
            entiteta) neće predstavljati nikakvu garanciju, ako ista nije izričito navedena u ovim uslovima. Gravis
            d.o.o. je prvenstveno izdavač i distributer, a ne tvorac publikacija ili ostalog sadržaja koji obezbjeđuju
            saradnici, autori, izdavačke kuće i drugi nosioci autorskih i/ ili elektroizdavačkih prava te ne snosi
            nikakvu odgovornost za njihov sadržaj. Gravis d.o.o. ne garantuju tačnost, kompletnost ili korisnost bilo
            kog sadržaja, niti će biti smatrane za odgovorne u slučaju bilo kakvog gubitka ili štete prouzrokovane
            oslanjanjem na informacije dobijene preko Tvornice snova.
        </p>
        <b>1.13. Korisnički sadržaj</b><br/>
        <p class="text-page-text">Korisnici Tvornice snova snose potpunu odgovornost za sadržaj koji su postavili ili učinili javno dostupnim
            preko aplikacije.
            Postavljanjem sadržaja na aplikaciji, korisnik ne prenosi na Gravis d.o.o. vlasništvo nad sadržajem, ali
            daje Gravis d.o.o. i Gravis d.o.o. prihvata neisključivo, besplatno i prenosivo pravo, da teritorijalno,
            vremenski i predmetno neograničeno koristi, distribuira, mijenja, kopira, javno saopštava i prikazuje,
            prevodi i prerađuje sadržaj, u skladu sa Politikom privatnosti. Također korisnik odobrava Gravis d.o.o.
            korištenje korisničkog imena, profilne slike i drugih informacija u vezi sa podacima i aktivnostima
            korisničkog profila.
            Korisnici ne mogu koristiti Tvornicu snova da bi podijelili bilo koji sadržaj koji je:
        <ul>
            <li>suprotan ovim uslovima, standardima i pravilima koja se odnose na korištenje Tvornice snova;</li>
            <li>nezakonit, prevara ili diskriminirajući;</li>
            <li>predstavlja kršenje prava trećih lica, uključujući i njihova prava intelektualne svojine.</li>
        </ul>
        Zabranjeno je korisnicima da šalju viruse ili bilo šta što može da onemogući, preoptereti ili naruši pravilno
        funkcionisanje ili izgled Tvornice snova. Korisnici ne mogu pristupati ili prikupljati podatke sa Tvornice snova
        automatskim sredstvima (bez našeg prethodnog odobrenja) ili pokušati pristupiti podacima za koje nemaju dozvolu
        pristupa.
        Zabranjeno je korisnicima da na Tvornici snova postavljaju bilo kakve sadržaje preuzete sa drugih sajtova,
        ukoliko nemaju pravo ili saglasnost za njihovo korištenje. Ukoliko bilo koji sadržaj koji korisnik postavi
        predstavlja kršenje autorskih i drugih prava trećih lica, ili postoji sumnja u postojanje kršenja, takav sadržaj
        biće uklonjen sa Tvornice snova aplikacije i/ ili pristup korisniku privremeno ograničen.
        </p>
        <b>1.14. Komunikacija između korisnika</b><br/>
        <p class="text-page-text"> Svi sadržaji koje korisnik postavi na Tvornicu snova, dostupni su svim drugim korisnicima i samim
            postavljanjem
            sadržaja korisnik daje neisključivo pravo drugim korisnicima na korištenje tih sadržaja, samo za privatnu i
            nekomercijalnu upotrebu, izuzev ukoliko bi takva upotreba predstavljala kršenje pravnih interesa nosioca
            prava.
            U slučaju pritužbi na poruke koje korisnik šalje drugim korisnicima, dalja komunikacija između tih korisnika
            biće blokirana na osnovu žalbe drugog korisnika.
            Preporučujemo korisnicima da prijave sadržaj ili ponašanje za koje smatraju da krši njihova prava
            (uključujući
            prava intelektualne svojine) ili naše uslove i pravila.
        </p>
        <b>1.15. Kontrola i brisanje profila</b><br/>
        <p class="text-page-text"> U slučaju postojanja sumnje da korisnik krši ove Uslove korištenja, ili ako krši autorska prava trećih lica,
            ili ukoliko postoji zakonski osnov ili obaveza za to, Gravis d.o.o. zadržava pravo da ukloni bilo koji
            sadržaj ili informaciju koju korisnik dijeli, da odbiju da korisniku pruže usluge, uključujući i brisanje
            korisničkog profila. Gravis d.o.o. ima pravo da ukloni bilo koji sadržaj za koji smatraju da je u
            suprotnosti sa važećim Zakonom o javnom informisanju ili nije u skladu sa obavezama Tvornice snova spram
            saradnika, autora, izdavačkih kuća i drugih nosioca autorskih i/ ili elektroizdavačkih prava. Tvornica snova
            može, po sopstvenom nahođenju i bez odgovornosti, da ukinu korisnički nalog ili zabrane daljnju upotrebu
            aplikacije/ sajta, ako ustanove ili posumnjaju da je korisnik djelovao suprotno ovim Uslovima korištenja.
            Bilo koje isključenje pristupa Tvornici snova po odredbama ovih uslova može biti izvedeno bez prethodnog
            obavještenja korisnika i Gravis d.o.o. može odmah da deaktivira ili obriše korisnički nalog i sve povezane
            informacije i/ ili zabrani budući pristup Tvornici snova. Ukoliko korisnik smatra da je njegov profil
            greškom ukinut, može se obratiti našoj podršci putem info@tvornicasnova.com.
        </p>
        <b>1.16. Naknada štete</b><br/>
        <p class="text-page-text"> Prihvatanjem Uslova korištenja Tvornice snova, korisnici daju saglasnost da obeštete Tvornicu snova odnosno
            Gravis d.o.o., kompanije koje sa njima sarađuju, njihove partnere i nosioce licenci i prava, u slučaju
            eventualnih tužbi, gubitaka, troškova, i šteta, uključujući i troškove advokata, koji proističu iz
            korisnikove
            upotrebe Tvornica snova aplikacije, a rezultat su aktivnosti koje se odnose na korisnikov nalog od strane
            samog
            korisnika ili bilo koje druge osobe koja je pristupila aplikaciji preko korisnikovog naloga.
        </p>
        <b>1.17. Reklamacije</b><br/>
        <p class="text-page-text">Reklamacije se mogu podneti samo u slučaju da se pojedinačno kupljeno elektronsko izdanje knjige ne može
            otvoriti u aplikaciji (čitaču).
            Reklamaciju, zajedno sa dokazom o nemogućnosti otvaranja sadržaja u vidu foto ili video zapisa, treba
            poslati na
            info@tvornicasnova.com. Nakon prijema reklamacije, ista će biti razmotrena u roku od tri radna dana i
            korisnik
            će biti obavješten o ishodu reklamacionog procesa.
        </p>
        <b>1.18. Politika povrata novca</b><br/>
        <p class="text-page-text">Gravis d.o.o. garantuju korisniku povrat novca u cijelosti u slučaju usvojene reklamacije.
            Gravis d.o.o. su u obavezi da povrat vrše isključivo u skladu sa pravilima, uslovima i regulativi kompanije
            Monri Payments d.o.o. (https://monri.com/).
            Gravis d.o.o. će direktno kontaktirati korisnika za povrat novca, putem e-maila, u vezi sa načinom povrata
            sredstava.
        </p>
        <b>1.19. Nemogućnost odustanka od ugovora</b><br/>
        <p class="text-page-text">U skladu sa Zakonom o zaštiti potrošača, potrošač nema pravo da odustane od ugovora zaključenog na daljinu, s
            obzirom da se radi o isporuci digitalnog (elektronskog) sadržaja koji nije isporučen na trajnom nosaču
            zapisa. Prihvatanjem ovih Uslova korištenja potvrđujete da ste upoznati sa nemogućnosti odustanka od
            kupovine poručenog sadržaja i plaćenih e-knjiga, odnosno da ste saglasni da kupovinom gubite pravo na
            odustanak od ugovora.
        </p>
        <b>1.20. Datum stupanja na snagu i modifikacija Uslova korištenja</b><br/>
        <p class="text-page-text">Ovi Uslovi korištenja stupaju na snagu 15.4.2021. godine i mogu biti ažurirani povremeno. O svim izmjenama
            korisnici će biti obavješteni objavljivanjem novih Uslova korištenja. Savjetujemo korisnike da redovno
            provjeravaju Uslove korištenja zbog mogućih promjena.
        </p>
        <b>1.21. Raskid sporazuma</b><br/>
        <p class="text-page-text">Sporazum stupa na snagu od momenta kada se korisnik složi sa Uslovima korištenja i počne da koristi Tvornicu
            snova, i traje neograničeno vrijeme. I Gravis d.o.o. i korisnik mogu raskinuti ovaj Sporazum u svakom
            trenutku. Korisnik može da raskine ovaj Sporazum tako što će poslati e-mail sa zahtjevom za ukidanje svog
            naloga na info@tvornicasnova.com, nakon čega nema više pravo na kupovinu novih i čitanje e-knjiga koje je
            već kupio unutar Tvornice snova.</p>

    </div>
</template>

<script>
export default {
    name: 'TermsOfService',
    beforeMount() {
        window.scrollTo(0, 0);
    },
};
</script>

<style lang="scss" scoped>
.tos-root {
    margin-top: 10rem;
    padding-top: 1rem;
    padding-left: 7%;
    padding-right: 7%;
    position: relative;
    z-index: 10000;

    b {
        font-size: 14px;
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title {
        font-size: 16px;
    }

}
</style>
