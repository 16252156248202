export const STORAGE_KEYS = {
    TOKEN: 'tvornicasnova_frontend_token',
    REFRESH_TOKEN: 'tvornicasnova_frontend_refresh_token',
};

export const APP_ENV = process.env.VUE_APP_ENV || 'dev';

export const IS_DEV = process.env.NODE_ENV === 'development';

export const MEDIA_URL = process.env.VUE_APP_STORAGE_URL || 'http://localhost:8001/';

export const MONRI_SCRIPT = `https://ipg${APP_ENV === 'dev' || APP_ENV === 'stage' ? 'test' : ''}.monri.com/dist/components.js`;
export const MONRI_AUTHENCITY_TOKEN = APP_ENV === 'prod' ? '658249969c817da010491c04148185db742af851' : '51163a90cc13c36c5282d446380110b7772d4846';

export const ROLES = {
    ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
    ROLE_PUBLISHER_ADMIN: 'ROLE_PUBLISHER_ADMIN',
    ROLE_USER: 'ROLE_USER',
};

export const ROLES_ACCESS_LEVELS = {
    ROLE_SUPER_ADMIN: 3,
    ROLE_PUBLISHER_ADMIN: 2,
    ROLE_USER: 1,
};

export const DISCOUNT_BASE_TYPES = {
    FIXED_BUY_ALL: 'FIXED_BUY_ALL',
    VARIABLE: 'VARIABLE',
};

export const DISCOUNT_BASE_TYPE_TEXT = {
    FIXED_BUY_ALL: 'Cijela knjiga',
    VARIABLE: 'Varijabilni',
};

export const DISCOUNT_OPERATOR_TYPES = {
    FIXED: 'FIXED',
    PERCENTAGE: 'PERCENTAGE',
};

export const DISCOUNT_OPERATOR_TYPES_TEXT = {
    FIXED: 'Fiksno',
    PERCENTAGE: 'Procent',
};

export const BOOK_STATUS = {
    RESERVED: 'RESERVED',
    IN_PROGRESS: 'IN_PROGRESS',
    AWAITING_APPROVAL: 'AWAITING_APPROVAL',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    PUBLISHED: 'PUBLISHED',
    UNPUBLISHED: 'UNPUBLISHED',
};

export const BOOK_STATUS_COLORS = {
    [BOOK_STATUS.RESERVED]: '#FEBF5E',
    [BOOK_STATUS.IN_PROGRESS]: '#FEBF5E',
    [BOOK_STATUS.AWAITING_APPROVAL]: '#4222F1',
    [BOOK_STATUS.APPROVED]: '#4222F1',
    [BOOK_STATUS.REJECTED]: '#F34658',
    [BOOK_STATUS.PUBLISHED]: '#26A53F',
    [BOOK_STATUS.UNPUBLISHED]: '#F34658',
};

export const ERROR_MESSAGES = {
    ERR_IMAGE_RESOLUTION_TOO_BIG: 'Slika prevelika',
    ERR_DISCOUNT_BASE_TYPE_CAN_NOT_BE_APPLIED: 'Samo varijabilni bonus se može primijeniti na kategorije.',
};
