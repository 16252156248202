<template>
    <div class="monri-page">
        <p class="monri-text">
            <b>Gravis društvo za odnose sa javnošću d.o.o. Sarajevo,</b><br/><br/>
            <b>Sudska registracija:</b> MBS: 65-01-0220-21<br/>
            <b>Identifikacijski broj:</b> 4202867330000<br/>
            <b>Adresa:</b> Hamze Orlovića 8, 71000 Sarajevo, Bosna i Hercegovina<br/>
            <b>Telefon:</b> <a href="tel:+38762385101">+38762385101</a><br/>
            <b>Email:</b> <a href="mailto:info@tvornicasnova.com">info@tvornicasnova.com</a><br/>
        </p>
        <hr/>

        <div class="monri-center"><b class="monri-title">SIGURNOST PLAĆANJA KREDITNIM KARTICAMA</b><br/><br/></div>
        <b class="monri-subtitle">* * * * Sigurnost plaćanja kreditnim karticama</b><br/><br/>
        <p class="monri-text">
            Tajnost Vaših podataka je zaštićena i osigurana upotrebom SSL enkripcije. Stranice za naplatu putem
            interneta osigurane su korištenjem Secure Socket Layer (SSL) protokola sa 128-bitnom enkripcijom podataka.
            SSL enkripcija je postupak šifriranja podataka radi sprječavanja neovlaštenog pristupa prilikom njihovog
            prenosa. Time je omogućen siguran prenos informacija te onemogućen nedozvoljen pristup podacima prilikom
            komunikacije između korisnikovog računara i WebPay servisa, te obratno. WebPay servis i finansijske ustanove
            razmjenjuju podatke upotrebom virtualne privatne mreže (VPN), koja je zaštićena od neautorizovanog pristupa.
            Monri Payment Gateway je sertifikovan prema PCI DSS Level 1 sigurnosnom standardu propisanom Visa i
            Mastercard pravilima. Trgovac ne pohranjuje brojeve kreditnih kartica i brojevi nisu dostupni neovlaštenim
            osobama. </p>
        <div class="monri-subtitle"><b> * * * Creditcard purchase security statement</b><br/></div>
        <p class="monri-text">
            Confidentiality of your information is protected and secured by using SSL encryption. Pages for web payment
            are secured by using Secure Socket Layer (SSL) protocol with 128-bit data encryption. SSL encryption is a
            data coding procedure for prevention of unauthorized access during data transfer. This enables a secure data
            transfer and prevents unauthorized data access during communication between user and Monri WebPay Payment
            Gateway and vice versa. Monri WebPay Payment Gateway and financial institutions exchange data by using their
            virtual private network (VPN) which is also protected from unauthorized access.
            Monri Payments is PCI DSS Level 1 certified payment service provider. Credit card numbers are not stored by
            Merchant and are not available to unauthorized personnel.
        </p>
        <div class="monri-center"><b class="monri-title">IZJAVA O PRIVATNOSTI</b><br/><br/></div>
        <div class="monri-subtitle"><b>* * * * Izjava o zaštiti i prikupljanju ličnih podataka</b><br/><br/></div>
        <p class="monri-text">
            Obavezujemo se pružati zaštitu ličnim podacima kupaca, na način da prikupljamo samo nužne, osnovne podatke o
            kupcima / korisnicima koji su neophodni za ispunjenje naših obaveza; informišemo kupce o načinu korištenja
            prikupljenih podataka, redovno dajemo kupcima mogućnost izbora o upotrebi njihovih podataka, uključujući
            mogućnost odluke žele li ili ne da se njihovo ime ukloni sa lista koje se koriste za marketinške kampanje.
            Svi podaci o korisnicima se strogo čuvaju i dostupni su samo zaposlenima kojima su ti podaci nužni za
            obavljanje posla. Svi naši zaposleni i poslovni partneri odgovorni su za poštovanje načela zaštite
            privatnosti.
        </p>

        <div class="monri-subtitle"><b>* * * * Personal data gathering and protection statement</b><br/><br/></div>
        <p class="monri-text">
            We are committed to provide service of protection of our customer's personal data in a way that we collect
            only essential basic information about our buyers that are necessary for fulfilling our obligations. We also
            inform our customers about the way we collect information and regularly give customers an option about how
            their information will be used, including the possibility to decide whether their name should be included or
            omitted from the lists used for marketing campaigns. All user information are strictly guarded and are
            available only to the employees who need those information for completing the job. All our employees and
            business partners are responsible to follow the principles of confidentiality protection.
        </p>

        <div class="monri-center"><b class="monri-title">USLOVI KUPOVINE</b><br/></div>
        <p class="monri-text">
            Ovim se uslovima utvrđuje postupak naručivanja, plaćanja, isporuke te reklamacija proizvoda koji su ponuđeni
            na ovim stranicama. Tvornicasnova.com Internet stranice mogu se koristiti za Vašu privatnu upotrebu bez
            ikakvih naknada za korištenje, a prema sljedećim uslovima i pravilima.
            Trgovac je Gravis d.o.o., a kupac je posjetitelj ovih stranica koji popuni elektronsku narudžbu, pošalje je
            trgovcu te izvrši plaćanje putem kreditnih kartica ili pouzećem.
        </p>
        <div class="monri-subtitle"><b>NARUČIVANJE</b><br/></div>
        <p class="monri-text">
            Kupac naručuje proizvod ili proizvode putem elektronskog obrasca narudžbe. Kupcem se smatra svaka osoba koja
            elektronski naruči barem jedan proizvod, popuni tražene podatk e i pošalje narudžbu. Sve cijene izražene su
            u Konvertibilnim markama, uključujući PDV.
            Kupac dodatno ima mogućnost prikaza cijena u američkim dolarima te evrima.
            Roba se naručuje elektronskim oblikom, pritiskom na određeni proizvod te spremanjem istog u Korpu.
            Roba se smatra naručenom u trenutku kada kupac odabere te potvrdi način plaćanja.
        </p>
        <div class="monri-subtitle"><b>PLAĆANJE</b><br/></div>
        <p class="monri-text">Naručeni proizvodi ili usluge plaćaju se online jednom od kreditnih kartica: Mastercard, Maestro
            ili Visa.
        </p>
        <div class="monri-subtitle"><b>ISPORUKA</b><br/></div>
        <p class="monri-text">Naručeni proizvodi pakuju se na način da tokom uobičajene manipulacije ne budu oštećeni.
            Naručeni proizvodi pakuju se na način da tokom uobičajene manipulacije ne budu oštećeni. Prilikom
            preuzimanja kupac je dužan provjeritistanje pošiljke te u slučaju oštećenja odmah reklamirati proizvod
            dostavljaču (zaposlenom kompanije koja vrši dostavu). Trgovac se odriče svake odgovornosti u vezi oštećenja
            koja mogu nastati prilikom dostave.
            Ako kupac ne primi robu, ili obavijest o isporuci, nakon što je poslana, u očekivanom vremenu, kupac ima
            pravo o tome obavijestiti prodavca kako bi se preduzele radnje pronalaska pošiljke ili kako bi se poslala
            zamjenska pošiljka. Ako kupac odbije primiti robu koju je naručio trgovac ima pravo tražiti od kupca
            nadoknadu svih troškova koji su vezani za isporuku.
            Trgovac se obvezuje poslati pošiljku kupcu onoga trenutka kada zaprimi potvrdu kojom se odobrava online
            transakcija. Nakon zaprimanja te potvrde trgovac se obvezuje poslati pošiljku u roku do 5 (pet) radnih dana.
            Nakon što dostavljač (kompanija koja vrši dostavu) zaprimi pošiljku od strane trgovaca, trgovac više nije
            odgovoran za daljnji tok isporuke i eventualna kašnjenja, te probleme koji mogu nastati u vezi daljnje
            manipulacije i isporuke robe. Ako kupac umjesto dostave izabere opciju downloada proizvoda, trgovac se
            obavezuje poslati obavijest isključivo u obliku elektronske pošte kupcu, na kojoj će se nalaziti linkovi za
            preuzimanje (download) i uputstva. Nakon prijema elektronske pošte (obavijesti o preuzimanju) kupac može
            odmah pristupiti preuzimanju proizvoda.
        </p>
        <div class="monri-subtitle"><b>REKLAMACIJE</b><br/></div>
        <p class="monri-text">Trgovac se obavezuje isporučiti proizvod koji je tehnički ispravan te odgovara opisu proizvoda
            navedenom na www.tvornicasnova.com. Slika koja ilustruje proizvod na Internet stranici ne mora odgovarati
            stvarnom izgledu proizvoda, te kupac ne može reklamirati ovaj segment.
            U slučaju tehničke neispravnosti audio zapisa koji su pohranjeni na prenosniku zvuka trgovac preuzima
            potpunu odgovornost. U slučaju tehničke neispravnosti, kupac je dužan reklamirati proizvod u roku od 4
            (četiri) radna dana. Nakon što reklamira tehničku neispravnost, kupac je dužan poštom uz dostavnicu, vratiti
            tehnički neispravan prenosnik zvuka nakon čega će trgovac u roku od 3(tri) radna dana poslati kupcu
            zamjenski proizvod, bez ikakve naknade. U slučaju gore navedenog, kupac nema pravo tražiti povrat novca, već
            isključivo zamjenski proizvod.
            Trgovac nije odgovoran za eventualna oštećenja i ostale obveze koje se nalaze u domeni dostavljača
            (preduzeće koja obavlja poslove dostave proizvoda).
            Ako kupac odabere opciju preuzimanja proizvoda te kupac ne bude u mogućnosti obaviti preuzimanje zbog
            tehničkih problema uzrokovanih na serveru trgovca, trgovac se obavezuje poslati elektronsku poštu sa novom
            mogućnošću za preuzimanje, za što kupac ne snosi nikakve troškove.
            U slučaju da kupac niti tada ne bude u stanju obaviti preuzimanje koji je uzrokovan zbog tehničkih problema
            na serveru trgovca, kupac je dužan o tome kontaktirati prodajnu službu prodavca koja će ponuditi adekvatno
            rješenje koje u sebi neće sadržavati nikakvu naknadu i daljnje troškove od strane kupca. Kupac nema pravo
            tražiti povrat novca u ovoj situaciji.
            U slučaju da kupac ne može preuzeti proizvod, a tehnički problemi nisu vezani za trgovca, već su uzrokovani
            od strane kupca, operatera ili treće osobe trgovac ne snosi nikakvu odgovornost i nije dužan kupcu omogućiti
            preuzimanje ili isporuku robe bez naknade.
            Kupac je u roku od 5 (pet) radnih dana dužan preuzeti proizvod, nakon toga više neće moći, bez obzira što je
            izvršio uplatu, preuzeti proizvod. U ovom slučaju kupac se odriče bilo kakve reklamacije.
        </p>

        <div class="monri-subtitle"><b>ZAŠTITA AUTORSKIH PRAVA</b><br/></div>
        <p class="monri-text">
            Sva prava izdavača i nosioca programa na snimljenom i štampanom djelu su zadržana.
            Izdavač je Gravis d.o.o. Kupac se obvezuje da neće neovlašteno umnožavati, izvoditi, koristi za radio
            difuziju te prodavati proizvode trgovca niti bilo koji dio tih proizvoda.
        </p>

        <div class="monri-subtitle"><b>UOPŠTE</b><br/></div>
        <p class="monri-text">
            Gravis d.o.o. zadržava pravo na izmjenu ovih uslova i pravila. Sve promjene primijeniće se na korištenje
            www.tvornicasnova.com stranice. Kupac snosi odgovornost za tačnost i potpunost unesenih podataka prilikom
            kupovine. Usluge koje Vam pruža www.tvornicasnova.com Internet prodavaonica ne uključuje troškove koje
            snosite koristeći računarsku opremu i usluge za pristup našim stranicama. Gravis d.o.o. nije odgovorna za
            troškove telefona, Internet prometa ili bilo koje druge troškove do kojih može doći. Iako Vam Gravis d.o.o.
            nastoji dati najbolju moguću ponudu usluga, Gravis d.o.o. ne može garantovati da će usluge na
            www.tvornicasnova.com odgovarati Vašim potrebama. Gravis d.o.o. takođe ne može garantovati da će usluga biti
            bez grešaka. Ukoliko dođe do greške, molimo Vas da je prijavite našem Kontakt centru ili na e-mail
            info@tvornicasnova.com kako bismo je otklonili na najbrži mogući način.
        </p>
        <div class="monri-center title"><b>IZJAVA O KONVERZIJI VALUTE</b><br/></div>
        <p class="monri-text">
            All payments will be affected in BAM currency. The amount your credit card account will be charged for is
            obtained through the conversion according to the current exchange rate of the local National bank. When
            charging your credit card, the same amount is converted into your local currency according to the exchange
            rate of credit card associations. As a result of this conversion there is a possibility of a slight
            difference from the original price stated in our web site.
            All payments will be affected in the currency of Bosnia and Herzegovina. The charged amount on your credit
            card account is converted into your local currency according to the exchange rate of credit card
            associations.
        </p>
    </div>
</template>

<script>
export default {
    name: 'MonriTos',
    beforeMount() {
        window.scrollTo(0, 0);
    },
};
</script>

<style lang="scss" scoped>

</style>
