import Vue from 'vue';
import VueRouter from 'vue-router';
import Profile from '@/views/Profile/Profile';
import { RouteLink } from '@/store/modules/menu';
import InternalWidgets from '@/views/InternalWidgets/InternalWidgets';
import Login from '@/views/Login/Login';
// import Store from '@/views/Store/Store';
import StripeCallback from '@/views/StripeCallback/StripeCallback';
import MonriPayment from '@/views/MonriPayment/MonriPayment';
import Home from '@/views/Home/Home';
import Contact from '@/views/Contact/Contact';
import TermsOfService from '@/views/TermsOfService/TermsOfService';
import CookiesPolicy from '@/views/CookiesPolicy/CookiesPolicy';
import MonriTos from "@/views/MonriTos/MonriTos";
import MonriCardSafety from '@/views/MonriCardSafety/MonriCardSafety';
import MonriPrivacyStatement from '@/views/MonriPrivacyStatement/MonriPrivacyStatement';
import MonriPurchaseTerms from '@/views/MonriPurchaseTerms/MonriPurchaseTerms';
import MonriCurrencyConversionStatement
    from '@/views/MonriCurrencyConversionStatement/MonriCurrencyConversionStatement';
import PrivacyPolicy from '@/views/PrivacyPolicy/PrivacyPolicy';
import SingleBook from '@/views/SingleBook/SingleBook';
import Ambassadors from '@/views/Ambassadors/Ambassadors';

Vue.use(VueRouter);

const routes = [
    {
        path: RouteLink.Home,
        name: 'Home',
        component: Home,
    },{
        path: RouteLink.SingleBook,
        name: 'SingleBook',
        component: SingleBook,
    }, {
        path: RouteLink.Contact,
        name: 'Contact',
        component: Contact,
    }, {
        path: RouteLink.Ambassadors,
        name: 'Ambassadors',
        component: Ambassadors,
    }, {
        path: RouteLink.PrivacyPolicy,
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    }, {
        path: RouteLink.Profile,
        name: 'Dashboard',
        component: Profile,
    }, {
        path: RouteLink.Login,
        name: 'Login',
        component: Login,
    },/* {
        path: RouteLink.Store,
        name: 'Store',
        component: Store,
    },*/ {
        path: RouteLink.TermsOfService,
        name: 'TermsOfService',
        component: TermsOfService,
    }, {
        path: RouteLink.CookiesPolicy,
        name: 'CookiesPolicy',
        component: CookiesPolicy,
    }, {
        path: RouteLink.InternalWidgets,
        name: 'Internal Widgets',
        component: InternalWidgets,
    }, {
        path: '/payment/stripe/callback',
        name: 'Stripe Callback',
        component: StripeCallback,
    }, {
        path: RouteLink.MonriPayment,
        name: 'Pay With Monri',
        component: MonriPayment,
    }, {
        path: RouteLink.MonriTos,
        name: 'MonriTos',
        component: MonriTos,
    }, {
        path: RouteLink.MonriCardPaymentSafety,
        name: 'MonriCardPaymentSafety',
        component: MonriCardSafety,
    },{
        path: RouteLink.MonriPrivacyStatement,
        name: 'MonriPrivacyStatement',
        component: MonriPrivacyStatement,
    },{
        path: RouteLink.MonriShoppingTerms,
        name: 'MonriShoppingTerms',
        component: MonriPurchaseTerms,
    },{
        path: RouteLink.MonriCurrencyConversionStatement,
        name: 'MonriCurrencyConversionStatement',
        component: MonriCurrencyConversionStatement,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
});

export default router;
