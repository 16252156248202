import Vue from 'vue'
import Vuex from 'vuex'
import menu from "@/store/modules/menu";
import login from '@/store/modules/login';
import coinsPackages from "@/store/modules/coinsPackages";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        menu,
        login,
        coinsPackages
    }
})
