// mutations
export const SET_LOGIN_ASYNC = 'SET_LOGIN_ASYNC';
export const SET_LOGIN_ERRORS = 'SET_LOGIN_ERRORS';
export const CLEAR_STATE = 'CLEAR_STATE';
export const SET_SIGN_UP_ERRORS = 'SET_SIGN_UP_ERRORS';
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USERS_ASYNC = 'SET_USERS_ASYNC';

// actions
export const PERFORM_LOGIN_ACTION = 'PERFORM_LOGIN_ACTION';
export const PERFORM_GOOGLE_LOGIN_ACTION = 'PERFORM_GOOGLE_LOGIN_ACTION';
export const PERFORM_FACEBOOK_LOGIN_ACTION = 'PERFORM_FACEBOOK_LOGIN_ACTION';

export const DO_ME_ACTION = 'DO_ME_ACTION';
export const PERFORM_SIGN_UP_ACTION = 'PERFORM_SIGN_UP_ACTION';
export const PERFORM_LOGOUT_ACTION = 'PERFORM_LOGOUT_ACTION';
export const EDIT_USER_ACTION = 'EDIT_USER_ACTION';
export const CHANGE_USER_PASSWORD_ACTION = 'CHANGE_USER_PASSWORD_ACTION';
export const GET_USER_BY_ID_ACTION = 'GET_USER_BY_ID_ACTION';
