<template>
    <div class="text-page">
        <div class="center"><b class="title">POLITIKA PRIVATNOSTI</b><br/></div>

        <p class="text-page-text">
            Za potrebe korištenja Tvornice snova, može se zahtijevati od korisnika pružanje određenih ličnih podataka,
            čije korištenje i čuvanje podliježe ovoj Politici privatnosti, koja predstavlja sastavni dio Uslova
            korištenja.<br/><br/>
            Lični podaci se mogu obrađivati i prenijeti samo u sklopu specifičnog zadatka i isključivo za svrhe u koje
            su prvobitno i dobiveni. Lični podaci će se prenijeti trećim stranama isključivo u skladu sa zakonskim
            okvirom i/ ili uz izričitu saglasnost. Isti se brišu ili se čine anonimnim odmah nakon ispunjenja svrhe u
            koju su dobiveni.<br/><br/>
            Ovom Politikom privatnosti uređuje se način na koji Tvornica snova, odnosno Gravis d.o.o. kao rukovalac,
            prikupljaja, koristi, održava i objavljuju informacije prikupljene od korisnika Tvornica snova, preko
            aplikacije/ sajta ili web sajta <a target="_blank" href="https://tvornicasnova.com">https://tvornicasnova.com</a>.<br/><br/>
            Korištenje aplikacije/ sajta podrazumijeva da je korisnik pročitao, razumio i složio se sa uslovima Politike
            privatnosti.<br/><br/>
            Ova Politika privatnosti se odnosi na Tvornica snova aplikaciju/ sajt i na Gravis d.o.o. i sve proizvode i
            usluge koje nude, a u skladu sa Zakonom o zaštiti podataka o ličnosti i Opštom regulativom o zaštiti
            podataka o ličnosti, odnosno General Data Protection Regulation (GDPR). Ova politika privatnosti se odnosi
            isključivo na aplikaciju i sajt Tvornica snova i web sajt <a target="_blank"
                                                                         href="https://tvornicasnova.com">https://tvornicasnova.com</a>,
            a ne i na druge
            sajtove, zato se preporučuje korisnicima da pročitaju sve izjave o privatnosti na drugim sajtovima koje
            posjećuju.<br/><br/>
        </p>

        <b class="text-page-subtitle">2.1. Lični podaci</b>
        <p class="text-page-text">
            Lični podaci podrazumijevaju bilo koju informaciju koja se odnosi na identifikaciju ili moguću
            prepoznatljivost fizičkog lica. Moguća prepoznatljivost fizičkog lica, podrazumijeva da se lice može
            identifikovati, direktno ili indirektno, pogotovo uz navođenje identifikacije kao što je e-mail adresa i
            slično.<br/><br/>
            Ako ste korisnik aplikacije ili posjetilac sajta, određeni podaci se prikupljaju automatski, kao što su IP
            adresa, regija ili uopštena lokacija gde je kompjuter ili uređaj korisnika pristupio internetu, tip
            pretraživača, operativni sistem ili druga informacija o korištenju naše aplikacije, uključujući i istoriju
            stranica koje je korisnik posjetio.<br/><br/>
            Podaci nisu lični ako se ne mogu povezati sa određenom osobom, kao npr. statistički podaci koje prikupljamo
            o ukupnoj posjeti određene stranice na sajtu ili u aplikaciji i ti podaci ne mogu direktno da upućuju na
            korisnika kao osobu.<br/><br/>
        </p>
        <b class="text-page-subtitle">2.2. Korisnicima se smatraju</b>
        <p class="text-page-text">
            Članovi, odnosno pravna ili fizička lica koja su se registrovala radi vremenski neograničenog korištenja
            aplikacije, a u svrhe kupovine elektronskih knjiga i koja nakon registracije prijavom pristupaju aplikaciji,
            a na način propisan uslovima korištenja.
        </p>
        <b class="text-page-subtitle">2.3. Koje informacije skuplja Gravis d.o.o.</b>
        <p class="text-page-text">
            Gravis d.o.o. skuplja podatke korisnika, koji mogu da uključe lične informacije, na
            različitim mjestima sa aplikacije, njenog internet sajta i preko njenog softvera a to su:
        <ul>
            <li>E-mail adresa</li>
            <li>IP adresa svakog pojedinačnog pristupa servisima</li>
            <li>Vrijeme kreiranja naloga</li>
            <li>Vrijeme modifikacije / mijenjanja podataka</li>
            <li>Vrijeme posljednjeg pristupa</li>
            <li>Informacije o kupovini izdanja</li>
        </ul>
        Gravis d.o.o. prikuplja lične informacije od korisnika samo ako korisnik dobrovoljno dostavi te informacije.
        Korisnici uvijek mogu odbiti da pruže identifikacione informacije i u tim slučajevima mogu biti uskraćeni za
        neke podatke ili aktivnosti na aplikaciji. Gravis d.o.o. ne sakuplja svjesno informacije o osobama mlađim od
        osamnaest godina.<br/><br/>
        2.3.1. Podaci prilikom kupovine<br/>
        Gravis d.o.o. skuplja podatke korisnika potrebne za plaćanje. Korisnici Tvornice snova
        imaju na raspolaganju plaćanje platnim karticama:
        <ul>
            <li>Mastercard</li>
            <li>Maestro</li>
            <li>VISA</li>
        </ul>
        Korisnik ne ostavlja podatke o kartici Gravis d.o.o. Sigurnost podataka prilikom kupovine, garantuje
        procesor platnih kartica. Niti jednog trenutka podaci o platnoj kartici nisu dostupni našem sistemu.<br/><br/>
        2.3.2. U koje svrhe Gravis d.o.o. koriste informacije koje su prikupile<br/>
        Gravis d.o.o. može prikupljati i koristiti lične podatke korisnika da bi:<br/>

        <ul>
            <li>Isporučio e-knjige i ostale proizvode koje ste kupili</li>
            <li>Verifikovao pristup zaštićenom sadržaju i informacijama sa naloga</li>
            <li>Odredio da li su ispunjeni minimalni sistemski zahtjevi za upotrebu Tvornice snova</li>
            <li>Ponudio nadogradnju</li>
            <li>Prikupio zbirne statističke podatke o korištenju Tvornice snova, uključujući internet komunikaciju i
                navike čitanja
            </li>
            <li>Poboljšao svoje usluge – informacije koje prikupljamo nam pomažu da efikasnije odgovorimo na zahtjeve
                korisnika prilikom kupovine izdanja
            </li>
            <li>Personalizovao iskustva korisnika – Možemo koristiti informacije da bi shvatili kako naši korisnici (kao
                grupa) koriste usluge i resurse koji se nalaze na Tvornici snova
            </li>
            <li>Unaprijedio Tvornicu snova – Možemo koristiti povratne informacije koje navedete za poboljšanje naših
                proizvoda i usluga
            </li>
            <li>Pokrenuo promocije, istraživanja ili druge funkcije Tvornice snova – Slanje informacija korisnicima koji
                su pristali da ih prime (Newsletter)
            </li>
            <li>Poslao periodične poruke – Gravis d.o.o. može da pošalju novim korisnicima poruku dobrodošlice, poruku
                koja potvrđuje kupovinu unutar Tvornice snova ili poruku potvrde o promjenama na nalogu
            </li>
        </ul>
        Gravis d.o.o. će poslati e-notifikaciju/ e-mail kada obavještava korisnika o novim izdanjima, kada ostvaruje
        komunikaciju u vezi sa kupovinom ili informiše korisnika o akciji ili popustu. Možemo koristiti notifikaciju/
        e-mail za slanje informacija i komunikaciju sa Korisnikom u cilju boljeg razumijevanja Korisnika o načinu rada
        Tvornica snova. Notifikacija/ e-mail se također može koristiti kao kanal komunikacije da odgovorimo na
        korisnikove molbe, pitanja i/ ili druge zahtjeve.<br/><br/>
        Ako u bilo kom trenutku korisnik želi da se odjavi od prijema budućih e-poruka, potrebno je da sa e-mail adrese
        kojom je korisnik prijavljen na Tvornicu snova pošalje e-mail na adresu <a href="mailto:info@tvornicasnova.com">info@tvornicasnova.com</a>.
        </p>
        <b class="text-page-subtitle">2.4. Sigurnosna procedura</b>
        <p class="text-page-text">
            Gravis d.o.o. koristi najsavremenije sigurnosne metode da bi zaštitio podatke. Vaše informacije se čuvaju u
            bazi podataka koja je zaštićena višestrukim zaštitama i dostupne su samo autorizovanom osoblju. Podaci
            nikada neće biti dostupni trećim licima bez saglasnosti korisnika.<br/><br/>
            2.4.1. Zaštita Gravis d.o.o. i korisnika Tvornice snova<br/>
            Gravis d.o.o. će obezbijediti lične informacije o nalogu isključivo ukoliko dobiju zakonski obavezujući
            nalog za to od nadležnih državnih organa Bosne i Hercegovine. Ovakav pristup je odgovarajući u cilju
            poštivanja zakonskih normi.
        </p>
        <b class="text-page-subtitle"></b>
        <p class="text-page-text"></p>
        <b class="text-page-subtitle">2.5. Korisnički profil / nalog</b>
        <p class="text-page-text">
            Korisnički profil je nalog korisnika sa mogućnošću zaštićenog pristupa preko lozinke. Na svom korisničkom
            nalogu možete vidjeti podatke o kupljenim i preuzetim e-knjigama, ukupnu količinu novca potrošenog na
            Tvornici snova, vremenu provedenom u čitanju i kada se to posljednji put desilo. Saglasni ste da se lični
            pristupni podaci smatraju povjerljivim i da ih ne otkrivate neovlašćenim trećim licima. Gravis d.o.o. ne
            preuzima nikakvu odgovornost za zloupotrebu lozinke, osim ako je Gravis d.o.o. odgovoran za istu. Ako ne
            možete pristupiti korisničkom nalogu, molimo Vas da se obratite na <a href="mailto:info@tvornicasnova.com">info@tvornicasnova.com</a>
            u najkraćem mogućem roku.
            Korisnik u slučaju prijavljivanja ostaje prijavljen sve dok se sam ne odjavi, ili restartuje svoj sistem ili
            aplikaciju. Preporučujemo da se odjavite nakon korištenja ukoliko uređaj dijelite sa drugim osobama.
        </p>
        <b class="text-page-subtitle">2.6. Poslovni transferi</b>
        <p class="text-page-text">
            Gravis d.o.o. može da prenese informacije koje sakupi, uključujući i lične informacije u slučaju prodaje
            većine ili kompletne imovine kompanije. Korisnik prihvata da se takvi transferi mogu dogoditi i da bilo koji
            novi vlasnik Gravis d.o.o. može da nastavi da koristi vaše lične informacije na način koji je određen našom
            politikom privatnosti.
        </p>
        <b class="text-page-subtitle">2.7. Prava korisnika</b>
        <p class="text-page-text">
            2.7.1. Pravo na informaciju<br/>
            Korisnik ima pravo da na zahtjev, u bilo kom trenutku od Gravis d.o.o. dobije informacije o ličnim podacima
            koji se odnose na korisnika, a koje je Gravis d.o.o. obradio u sklopu člana 2. politike
            privatnosti.<br/><br/>
            2.7.2. Pravo na prepravku<br/>
            Korisnik ima pravo da zahtjeva promjenu ličnih podataka koji se odnose na njega ukoliko su ti podaci
            netačni.<br/><br/>
            2.7.3. Pravo na brisanje<br/>
            Korisnik ima pravo da zahtjeva brisanje ličnih podataka u skladu sa uslovima korištenja.<br/><br/>
            2.7.4. Pravo na ograničenje obrade podataka<br/>
            Korisnik može da zahtjeva da lične podatke koji se odnose na njega Gravis d.o.o. obrađuju samo do određene
            mjere.<br/><br/>
            2.7.5. Pravo na prenosivost podataka<br/>
            Korisnik ima pravo da zatraži lične podatke koji se odnose na njega. Gravis d.o.o. je u obavezi da odgovori
            na primljeni zahtjev u roku od mjesec dana. Podatke možemo poslati direktno korisniku ili drugom izvršitelju
            obrade ako korisnik tako zahtijeva. Slanje podataka je besplatno, ali u slučaju da Gravis d.o.o. u kratkom
            vremenskom periodu dobije mnogo zahtjeva i posumnja da je akcija odrađena od strane grupe korisnika koji
            imaju lošu namjeru u smislu da uspore rad kompanije, Gravis d.o.o. ima pravo da naplati naknadu koja pokriva
            stvarne troškove traženja i sortiranja podataka i eventualnog materijala ako primjerak šalju u papirnom
            obliku ili na eksternim medijima.<br/><br/>
            2.7.6. Pravo na prigovor<br/>
            Korisnik ima pravo da u svakom trenutku uloži prigovor na obradu svojih ličnih podataka. U tom slučaju
            možete zatražiti da prestanemo sa korištenjem svih ili nekih od vaših ličnih podataka (npr. ako nemamo
            zakonsko pravo da nastavimo da ih upotrebljavamo) ili ograničiti našu upotrebu (npr. ako su podaci netačni).
            Pravo na prigovor je poseban oblik prava na brisanje.<br/><br/>
            2.7.7. Pravo na opoziv pristanka i obustavljanje obrade podataka<br/>
            Ukoliko, u bilo kom trenutku, korisnik želi da ugasi nalog i obustavi obradu ličnih podataka, potrebno je
            poslati e-mail na <a href="mailto:info@tvornicasnova.com">info@tvornicasnova.com</a>. Lične informacije će
            biti izbrisane iz baze aktivnih korisnika pod
            uslovom da su ispunjene sve obaveze plaćanja, da Gravis d.o.o. vjeruje da nije potrebno čuvati te
            informacije zbog nekog sudskog procesa i da nije ni pod kakvom zakonskom obavezom da čuva te informacije.
            Budite svjesni da će u toj situaciji sve e-knjige koje se nalaze u vašoj biblioteci biti obrisane bez
            povrata novca i da ćete ponovo morati da se registrujete da biste koristili Tvornicu snova.<br/><br/>
            2.7.8. Ostvarivanje navedenih prava<br/>
            Ako želite da ostvarite navedena prava, obratite nam se na <a href="mailto:info@tvornicasnova.com">info@tvornicasnova.com</a>.<br/><br/>
        </p>
        <b class="text-page-subtitle">2.8. Marketing – oglašavanje i društvene mreže</b>
        <p class="text-page-text">
            Ukoliko korisnik dâ saglasnost da želi da prima personalizovane ponude, upotrebljavat će se lični podatak –
            e-mail adresa. Gravis d.o.o. koristi postojeće podatke kao što su potvrda prijema i potvrda čitanja
            newslettera, istorija kupovina, datum i vrijeme posjete ili proizvodima koje ste pogledali. Za slanje
            newslettera i analize korisničkog ponašanja, Gravis d.o.o. koristi Google Analytics. Osim toga, analiziraju
            se informacije kao što su odabrana izdanja, kao i automatski generisani podaci kao što su potrošnja ili
            trenutna lokacija. Koristimo ove informacije kako bismo poboljšali naše proizvode i usluge, kako bismo ih
            bolje prilagodili potrebama korisnika i kako bismo komunicirali sa korisnikom o ostalim proizvodima u ponudi
            Gravis d.o.o. koje bi ga mogle zanimati.<br/><br/>
            Prilikom registracije, korisnik automatski prihvata slanje marketinških poruka. Ako u bilo kom trenutku
            korisnik želi da se odjavi od prijema buduće e-poruke, potrebno je da sa e-mail adrese kojom je korisnik
            prijavljen na Tvornicu snova pošalje poruku o odjavljivanju na adresu <a
            href="mailto:info@tvornicasnova.com">info@tvornicasnova.com</a>.<br/><br/>
            2.8.1. Newsletter<br/>
            Za isporuku newslettera koristimo svoj server. Prikupljamo statističke podatke o otvaranju e-pošte i o
            klikovima u okviru e-biltena, pomoću standardnih tehnologija kako bismo mogli da pratimo i poboljšavamo
            sadržaj svog newslettera.<br/><br/>
            2.8.2. Korisnički servis putem društvenih mreža<br/>
            Ako korisnik kontaktira Gravis d.o.o. preko društvenih mreža, lični podaci korisnika, koji su u vezi sa
            rješavanjem potencijalnog problema, a koje korisnik javno objavi kroz svoj profil ili dostavi u direktnoj
            poruci na profilu naše kompanije, obrađuju se i čuvaju u sistemu za upravljanje korisnicima, u svrhu
            servisiranja/ rješavanja problema. Ipak, sama poruka, ako je javno objavljena, neće biti izbrisana sa
            profila.<br/><br/>
        </p>
        <b class="text-page-subtitle">2.9. Analiza korištenja Sajta</b>
        <p class="text-page-text">
            Kako bismo stekli uvid u potrebe posjetilaca Tvornice snova, koristimo razne softvere za analizu korištenja
            iste. Ovo nam pomaže da konstantno unapređujemo kvalitet naših ponuda.<br/><br/>
            2.9.1. Google Analytics<br/>
            Tvornica snova koristi Google Analytics, uslugu web analize koju pruža Google Inc. (“Google”). Google
            Analytics upotrebljava takozvane “kolačiće”, tekstualne datoteke koje su sačuvane na računaru/ uređaju
            Korisnika i koje omogućavaju analizu korištenja Tvornice snova. Informacije koje generiše kolačić o
            korištenju obično se šalju Google serveru u SAD i tamo se čuvaju. Međutim, ako je IP anonimizacija
            aktivirana na Sajtu, Google će unaprijed skratiti IP adresu u državama članicama Evropske Unije ili u drugim
            zemljama ugovornicama Sporazuma o Evropskoj ekonomskoj uniji. Samo u izuzetnim slučajevima puna IP adresa će
            biti poslana na Google server u SAD i tamo će se skratiti. U ime Gravis d.o.o., Google će koristiti ove
            informacije kako bi analizirao upotrebu Tvornice snova, sačinio izvještaje o aktivnostima na njoj i pružio
            druge usluge vezane za korištenje Tvornice snova i interneta. Google neće IP adresu korisnika dovoditi u
            vezu sa drugim podacima kompanije Google. Možete spriječiti prikupljanje podataka koje generišu kolačići
            (uključujući Vašu IP adresu) kao i obradu tih podataka od strane Google instaliranjem plug-in-a sa sljedećeg
            linka: <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">http://tools.google.com/dlpage/gaoptout</a><br/>
            Načela sigurnosti i privatnosti usluge Google Analytics možete pronaći ovdje:
            <a href="https://support.google.com/analytics/answer/6004245" style="word-break: break-all" target="_blank">https://support.google.com/analytics/answer/6004245</a>.<br/><br/>
            2.9.1.1. AdWords<br/>
            Tvornica snova koristi “Google Remarketing” kako bi ponudila oglase na drugim platformama koji su u vezi sa
            ponašanjem korisnika na sajtu. Ako kliknete na određenu stranicu ili proizvod na sajtu
            <a href="https://tvornicasnova.com" target="_blank">https://tvornicasnova.com</a>, Google će sačuvati vaša
            interesovanja o našim uslugama/ proizvodima ili
            sadržajima i može s vremena na vreme prikazivati Google-ove oglase “Adwords” koji odgovaraju vašim
            interesovanjima, na različitim platformama koje posjećujete. Ovaj oglas može se odnositi na ponudu Gravis
            d.o.o., ali i ponude trećih strana. Da bi se navedeno sprovelo, Google čuva kolačić na računaru korisnika
            koji omogućuje da se interesovanja korisnika dodijele računaru/ uređaju korisnika (pojedinosti o kolačićima
            potražite u dijelu “Google Analytics”). Ta interesovanja se anonimno bilježe i nisu povezana sa ličnim
            podacima. Na stranici <a href="https://www.google.com/ads/preferences" target="_blank">https://www.google.com/ads/preferences</a>
            se mogu kontrolisati informacije koje
            Google koristi za prikazivanje oglasa. Na ovoj adresi također se može onemogućiti upotreba kolačića od
            strane Google-a.<br/>
            Ako korisnik pristane da Google vrši povezivanje istorije pretraživanja interneta i aplikacija sa Google
            nalogom i da koristi informacije koje su pregledane na internetu sa Google nalogom korisnika u cilju
            personalizovanja oglasa, Google povezuje te informacije sa podacima Google Analytics-a da bi stvorio i
            definisao publiku za remarketing na svim uređajima koje Korisnik koristi, a na kojima postoji korisnikov
            Google nalog. Da bi se podržala ova funkcionalnost, Google Analytics prikuplja jedinstvene ID-eve.
            Podešavanja se mogu promijeniti u Google odjeljku „moj nalog“.<br/><br/>
            2.9.1.2. YouTube<br/>
            Naš sajt može koristiti softverske dodatke („plug-in“) sa YouTube-a koji pripada kompaniji Google Inc. Čim
            se posjete web stranice s YouTube plug-inom, dolazi do povezivanja sa YouTube serverima. Serveru Youtube-a
            biće rečeno koje stranice na sajtu Gravis d.o.o. ste posjetili. Ukoliko ste ulogovani na YouTube nalog,
            YouTube-u je omogućeno da poveže pretragu korisnika sa ličnim profilom korisnika. Ova mogućnost se može
            ukinuti ako se prethodno korisnik odjavi sa svog naloga. Više informacija o prikupljanju i upotrebi ličnih
            podataka od strane YouTube-a, potražite u pravilima o privatnosti kompanije Youtube:
            <a href="https://www.youtube.com/t/terms" target="_blank">https://www.youtube.com/t/terms</a>.<br/><br/>
        </p>
        <b class="text-page-subtitle">2.10. Obavještenje o promjenama</b>
        <p class="text-page-text">Gravis d.o.o. može s vremena na vrijeme da preradi politiku privatnosti a da bi je
            održao u skladu sa svojim proizvodima i uslugama ili važećim propisima. Datum ažuriranja služi da podsjeti
            korisnika na nedavne modifikacije. U bilo kom trenutku, korisnik može da pismenim putem, slanjem e-mail
            poruke na <a
                href="mailto:info@tvornicasnova.com">info@tvornicasnova.com</a> obavijesti Gravis d.o.o. da ne prihvata
            promjene u politici privatnosti što
            automatski dovodi do otkazivanja korisničkog naloga od strane korisnika.
        </p>
        <b class="text-page-subtitle">2.11. Izmjene, datum stupanja na snagu</b>
        <p class="text-page-text">Ova politika privatnosti se primjenjuje na korištenje naše aplikacije, sajta i usluga
            od strane korisnika od „Dana stupanja na snagu”. Možemo da povremeno izmijenimo u cijelosti ili djelomično
            ovu Politiku privatnosti, bez ličnog obavještavanja. Pozivamo Vas da periodično pogledate Politiku
            privatnosti a kako biste ostali informisani o postupanju Tvornice snova. Ako se protivite bilo kakvim
            izmjenama, molimo da prestanete sa korištenjem naše aplikacije i/ ili zatvorite svoj nalog. Ukoliko po
            objavljivanju izmjena ove Politike privatnosti korisnik nastavi sa kontinuiranom upotrebom aplikacije i
            naših usluga podrazumijevat će se da je prikupljanje, upotreba ili dijeljenje ličnih podataka predmet
            izmijenjene verzije Politike privatnosti i smatrat će se pristankom sa navedenim izmjenama.</p>
        <b class="text-page-subtitle">2.12. Kako da nas kontaktirate</b>
        <p class="text-page-text">
            Ukoliko imate bilo kakva pitanja na temu Politike privatnosti Gravis d.o.o. ili načina na koji se koriste
            Vaše lične informacije, molimo Vas da nas kontaktirate putem emaila <a
            href="mailto:info@tvornicasnova.com">info@tvornicasnova.com</a>.<br/><br/>
            Datum ažuriranja: 10.5.2022. godine
        </p>

    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy',
};
</script>

<style scoped lang="scss">
.text-page {
    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title {
        font-size: 16px;
    }
}
</style>
