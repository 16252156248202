<template>
    <div class="ambassadors-page">
        <div class="ambassadors-content">
            <div class="ambassador">
                <div class="ambassador-image">
                    <img :src="mfwLogo"/>
                </div>
                <div class="ambassador-name">
                    <a href="https://myfitworld.net/">MyFitWorld</a>
                </div>
                <div class="ambassador-title">

                </div>
                <div class="ambassador-description">
                    <p style="font-size: 18px">
                        MyFitWorld web i mobilna aplikacija je namijenjena trenerima, njihovim klijentima, ali i svima
                        koji
                        žele da nađu trenera ili treniraju uz ekspertski kreirane treninge. Može se koristiti na
                        računaru,
                        laptopu, tabletu ili telefonu.
                        MyFitWorld trenerima omogućava:
                    </p>
                    <ul>
                        <li>Hiljade video snimaka vježbi, treninga i programa i mogućnost da i sami kreirate svoje</li>
                        <li>Kopiranje sadržaja po datumima i na druge klijente</li>
                        <li>Rasporede, kalendare aktivnosti klijenata, pisanje ishrane</li>
                        <li>Notifikacije i podsjetnike za treninge i rođendane klijenata</li>
                        <li>Fotografije napretka i statistiku</li>
                        <li>Predstavi svoje usluge korisnicima bilo gdje u svijetu uz pomoć MFWNet socijalne mreže</li>
                        <li>Radiš bilo kada i bilo gdje</li>
                        <li>Digitalizuj svoje poslovanje i obezbijedi prihode čak i kada nisi prisutan/na uživo sa
                            klijentima
                        </li>
                        <li>Uštedi i do 200% vremena za pravljenje treninga i programa, analitiku i organizaciju
                            poslovanja
                        </li>
                    </ul>
                    Vježbači:
                    <ul>
                        <li>Pronađite idealnog trenera za sebe, bilo gdje i bilo kada,</li>
                        <li>Trenirajte uz besplatan sadržaj koji treneri postavljaju na MFWNet,</li>
                        <li>Pretplatite se na MyFitWorld PREMIUM za kompletnu statistiku aktivnosti, historiju tjelesnog
                            sastava, neograničenu bazu omiljenih sadržaja, 2 000+ vježbi i 700+ ekspertski kreiranih
                            treninga,
                        </li>
                        <li>Ostvarite pogodnosti plaćanja za usluge i proizvode kod MyFitWorld prodajnih partnera!</li>
                    </ul>

                    Za sve Tvorničare snova, MyFitWorld je omogućio sljedeće POPUSTE za MyFitWorld PREMIUM naloge sa
                    pratećim PROMO KODOVIMA:

                    <ul>
                        <li>
                            20% popusta na mjesečnu pretplatu: TVORNICASNOVA20
                        </li>
                        <li>
                            25% popusta na godišnju pretplatu: TVORNICASNOVA25
                        </li>
                    </ul>

                    Uživajte!
                    Zapratite nas za više informacija:
                </div>
                <div class="ambassador-social">
                    <a href="https://www.facebook.com/myfitworldteam" target="_blank">
                        <img class="social-icon" :src="facebookLogo"/>
                    </a>
                    <a href="https://www.instagram.com/myfitworldteam/" target="_blank">
                        <img class="social-icon" :src="instagramLogo"/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCusPqVO2WDEK6MzWGJZuH5Q/videos" target="_blank">
                        <img class="social-icon" :src="youtubeLogo"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mfwLogo from '@/assets/ambassador-logos/mfw.png';
import youtube from '@/assets/imgs/yt_icon.svg';
import instagram from '@/assets/imgs/instagram.svg';
import facebook from '@/assets/imgs/facebook.svg';

export default {
    name: 'Ambassadors',
    methods: {},
    computed: {
        mfwLogo() {
            return mfwLogo;
        },
        youtubeLogo() {
            return youtube;
        },
        instagramLogo() {
            return instagram;
        },
        facebookLogo() {
            return facebook;
        },
    },
};
</script>

<style scoped lang="scss">
.ambassadors-page {
    width: 100%;

    .ambassadors-content {
        position: relative;
        z-index: 5;
        min-height: 100vh;
        padding-top: 8rem;
        display: flex;
        flex-direction: column;
        padding-left: 7%;
        padding-right: 7%;

        .social-icon {
            margin-top: 1rem;
            margin-bottom: 0.25rem;
            width: 32px;
            margin-right: 0.75rem;
        }

        .ambassador {
            width: 33.3%;

            .ambassador-image {
                text-align: center;

                img {
                    max-width: 50%;
                }
            }

            .ambassador-social {
                text-align: center;
            }

            .ambassador-name {
                width: 100%;
                text-align: center;
                margin-top: 1rem;
                margin-bottom: 1rem;

                a {
                    text-decoration: none;
                    color: #222;
                    font-size: 1.5rem;
                }
            }
        }
    }

    @media($mobileBreak) {
        .ambassadors-content {
            .ambassador {
                width: 100%;

                font-size: 16px;

                .ambassador-name {
                    a {
                        font-size: 2.5rem;
                    }
                }
            }
        }
    }
}
</style>
