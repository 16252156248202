import Vue from 'vue';
import { BOOK_STATUS, DISCOUNT_BASE_TYPE_TEXT } from "@/utils/consts";

Vue.filter('mergeName', function (user) {
    if (!user) return '';

    if (!user.first_name && !user.last_name) {
        return "N.N.";
    }

    return user.first_name + (user.middle_name ? `${user.middle_name} ` : ' ') + user.last_name;
});

Vue.filter('parseCategories', function (categories) {
    if (!categories?.length) return '';

    return categories[0].name;
});

Vue.filter('parseBookStatus', function (bookStatus) {
    switch (bookStatus) {
        case BOOK_STATUS.RESERVED:
            return "Zakazano";
        case BOOK_STATUS.IN_PROGRESS:
            return "U izradi";
        case BOOK_STATUS.AWAITING_APPROVAL:
            return "Čeka odobrenje";
        case BOOK_STATUS.REJECTED:
            return "Odbijeno";
        case BOOK_STATUS.APPROVED:
            return "Odobreno";
        case BOOK_STATUS.PUBLISHED:
            return "Objavljeno";
        case BOOK_STATUS.UNPUBLISHED:
            return "Odjavljeno";
    }

    return "";
});

Vue.filter('parseDiscountType', function (type) {
    if (!type) return "";

    return DISCOUNT_BASE_TYPE_TEXT[type];
});

Vue.filter('parseCoinsPackagePrice', function (coinsPackage) {
    return (coinsPackage?.price_in_cents ?? 0) / 100;
});

Vue.filter('parseCoinsPackagePriceBam', function (coinsPackage) {
    return (Math.round((coinsPackage?.price_in_cents ?? 0) * 1.95583)) / 100;
});
