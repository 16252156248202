<template>
    <div :class="{'root-layout': true}">
        <div class="root-background" v-if="!isPayment">
            <Header/>

            <div class="bubble-2"/>
        </div>

        <transition name="fade">
            <router-view/>
        </transition>

        <div class="footer">
            <div class="footer-content">
                <div class="top">
                    <div class="appicon-title">
                        <div class="appicon">
                            <img src="./assets/imgs/home/transparent_appicon.png"/>
                        </div>
                        <div class="title">
                            Tvornica snova
                        </div>
                    </div>
                    <div class="monri-payweb">
                        <a href="https://www.mastercard.com" target="_blank">
                            <img :src="mastercardLogo"/>
                        </a>
                        <a href="https://brand.mastercard.com/brandcenter/more-about-our-brands.html" target="_blank">
                            <img :src="maestroLogo"/>
                        </a>
                        <a href="https://www.visaeurope.com" target="_blank">
                            <img :src="visaLogo"/>
                        </a>
                        <a href="#">
                            <img :src="mastercardIdCheckLogo" style="width: 90px;margin: 15px"/>
                        </a>
                        <a href="https://www.visaeurope.com/making-payments/verified-by-visa/"
                           target="popup"
                           onclick="window.open('https://www.visaeurope.com/making-payments/verified-by-visa/','visa-secure','width=540,height=450')">
                            <img :src="visaSecure" style="width: 90px; margin: 15px"/>
                        </a>
                        <a class="pay-at-web" href="https://monri.com" target="_blank">
                            <img :src="unicreditMonri" style="width: 180px; margin: 15px"/>
                        </a>

                    </div>
                </div>
                <div class="bottom">
                    <div class="memorandum">
                        © 2022, Tvornica snova
                        <br/>
                        <a href="https://www.facebook.com/tvojatvornicasnova" target="_blank">
                            <img class="social-icon" :src="facebookLogo"/>
                        </a>
                        <a href="https://www.instagram.com/tvojatvornicasnova" target="_blank">
                            <img class="social-icon" :src="instagramLogo"/>
                        </a>
                        <a href="https://www.tiktok.com/@tvornicasnova" target="_blank">
                            <img class="social-icon" :src="tiktokLogo"/>
                        </a>
                        <a href="https://www.linkedin.com/company/tvornicasnova/" target="_blank">
                            <img class="social-icon" :src="linkedInLogo"/>
                        </a>
                        <a href="https://twitter.com/Tvornica__snova" target="_blank">
                            <img class="social-icon" :src="twitterLogo"/>
                        </a>
                        <a href="https://www.youtube.com/channel/UCqsDn5rACk6FrNHKPHz2AUQ" target="_blank">
                            <img class="social-icon" :src="youtubeLogo"/>
                        </a>
                        <br/><br/>
                        <div class="title" style="color: #FF8F66;">
                            #oplemenium
                            <!--                        <div class="legal-info">-->
                            <!--                            <div>Sudska registracija: MBS: 65-01-0220-21</div>-->
                            <!--                            <div>Identifikacijski broj: 4202867330000</div>-->
                            <!--                            <div>Adresa: Hamze Orlovića 8, 71000 Sarajevo, Bosna i Hercegovina</div>-->
                            <!--                            <div>Email: info@tvornicasnova.com</div>-->
                            <!--                        </div>-->
                        </div>
                        <div class="links" v-if="isMobile">
                            <div class="tos-cookies">
                                <a @click="goToMonri(0)">
                                    Sigurnost plaćanja kreditnim karticama
                                </a>
                                <a @click="goToMonri(1)">
                                    Izjava o privatnosti
                                </a>
                                <a @click="goToMonri(2)">
                                    Uslovi kupovine
                                </a>
                                <a @click="goToMonri(3)">
                                    Izjava o konverziji valute
                                </a>
                                <a @click="goToTOS">
                                    Uslovi korištenja
                                </a>
                                <a @click="goToPrivacyPolicy">
                                    Politika privatnosti
                                </a>
                                <a @click="goToCookiesPolicy">
                                    Kolačići
                                </a>
                            </div>
                            <!--                        <a @click="goToTop" class="go-to-top">-->
                            <!--                            Nazad na vrh.-->
                            <!--                        </a>-->
                        </div>
                    </div>
                    <div class="links" v-if="!isMobile">
                        <div class="tos-cookies">
                            <a @click="goToMonri(0)">
                                Sigurnost plaćanja kreditnim karticama
                            </a>
                            <a @click="goToMonri(1)">
                                Izjava o privatnosti
                            </a>
                            <a @click="goToMonri(2)">
                                Uslovi kupovine
                            </a>
                            <a @click="goToMonri(3)">
                                Izjava o konverziji valute
                            </a>
                            <a @click="goToTOS">
                                Uslovi korištenja
                            </a>
                            <a @click="goToPrivacyPolicy">
                                Politika privatnosti
                            </a>
                            <a @click="goToCookiesPolicy">
                                Kolačići
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import { DO_ME_ACTION } from '@/store/actions/login';
import { RouteLink } from '@/store/modules/menu';

import '@/assets/scss/root-layout.scss';
import Header from '@/components/Ui/Header/Header';
import { STORAGE_KEYS } from '@/utils/consts';
import mastercardLogo from '@/assets/imgs/logotypes/mastercard.png';
import maestroLogo from '@/assets/imgs/logotypes/maestro.png';
import visaLogo from '@/assets/imgs/logotypes/visa.gif';
import toktok from '@/assets/imgs/tiktok_icon.svg';
import mastercardIdCheckLogo from '@/assets/imgs/logotypes/mastercard-idcheck.png';
import visaSecure from '@/assets/imgs/logotypes/visa-secure.jpg';
import unicreditMonri from '@/assets/imgs/logotypes/monri-unicredit.png';
import facebook from '@/assets/imgs/facebook.svg';
import youtube from '@/assets/imgs/yt_icon.svg';
import linkedin from '@/assets/imgs/linkedin_icon.svg';
import twitter from '@/assets/imgs/tw_icon.svg';
import instagram from '@/assets/imgs/instagram.svg';
import { checkIfMobile } from '@/utils/utils';

export default {
    components: { Header },
    beforeMount() {

        const securedRoutes = [RouteLink.Profile, RouteLink.Store];

        if (securedRoutes.includes(this.$route.path)) {
            if (!this.isLogin) {
                this.$router.replace(RouteLink.Home);
            }
        }
    },
    mounted() {
        if (this.user) {
            return;
        }

        const token = localStorage.getItem(STORAGE_KEYS.TOKEN);

        if (token && !this.user) {
            this.doMe();
        }
    },

    methods: {
        ...mapActions({
            doMe: DO_ME_ACTION,
        }),
        goToMonri(pageIndex) {
            const routes = [RouteLink.MonriCardPaymentSafety, RouteLink.MonriPrivacyStatement, RouteLink.MonriShoppingTerms, RouteLink.MonriCurrencyConversionStatement];

            this.$router.push(routes[pageIndex]).catch(() => {
            });
        },
        goToTOS() {
            this.$router.push(RouteLink.TermsOfService).catch(() => {
            });
        },
        goToPrivacyPolicy() {
            this.$router.push(RouteLink.PrivacyPolicy).catch(() => {
            });
        },
        goToCookiesPolicy() {
            this.$router.push(RouteLink.CookiesPolicy).catch(() => {
            });
        },
    },
    computed: {
        ...mapGetters([
            'isDrawerExpanded',
        ]),
        isMobile() {
            return window.matchMedia('max-width: 480px') && checkIfMobile();
        },
        twitterLogo() {
            return twitter;
        },
        youtubeLogo() {
            return youtube;
        },
        linkedInLogo() {
            return linkedin;
        },
        instagramLogo() {
            return instagram;
        },
        tiktokLogo() {
            return toktok;
        },
        facebookLogo() {
            return facebook;
        },
        mastercardLogo() {
            return mastercardLogo;
        },
        maestroLogo() {
            return maestroLogo;
        },
        visaLogo() {
            return visaLogo;
        },
        mastercardIdCheckLogo() {
            return mastercardIdCheckLogo;
        },
        visaSecure() {
            return visaSecure;
        },
        unicreditMonri() {
            return unicreditMonri;
        },

        isPayment() {
            return this.$route.path === RouteLink.MonriPayment;
        },
        isLogin() {
            return this.$route.name === 'Login';
        },
        async() {
            return this.$store.state.login.async;
        },
        user() {
            return this.$store.state.login.user;
        },
    },
};
</script>

<style lang="scss">

html, body, #app {
    padding: 0px;
    margin: 0px;
    height: 100%;
    background: $pageBg;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: $pageBg;
}

.root-layout {
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    overflow-y: auto;

    .root-background {
        position: relative;
    }

    &.drawer-expanded {

        .drawer {
            width: $expandedDrawerWidth;
        }

        .header {
            left: $expandedDrawerWidth;
        }

        .main-view {
            padding-left: $expandedDrawerWidth;
        }
    }

    .footer {
        z-index: 5;

        .footer-content {
            padding: 1.5% 7% 4%;

            .top {
                display: flex;
                align-items: center;
                padding-bottom: 1%;
                border-bottom: 1px solid #E3E6E9;
                justify-content: space-between;

                .appicon-title {
                    display: flex;
                    align-items: center;
                }

                .appicon {
                    background: #3E206D;
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 0.75rem;

                    img {
                        object-fit: contain;
                        width: 2rem;
                        // ovo works, samo storm ne konta
                        image-rendering: -webkit-optimize-contrast;
                        height: 2rem;
                    }
                }

                .monri-payweb {
                    display: flex;
                    align-items: center;

                    a {
                        display: block;
                    }

                    .pay-at-web {
                        img {
                            // ovo works, samo storm ne konta
                            image-rendering: -webkit-optimize-contrast;
                            height: 4rem;
                        }
                    }

                }

                .title {
                    padding-left: 1rem;
                    font-family: Poppins, sans-serif;
                    font-weight: 600;
                    font-size: 1.75rem;
                    line-height: 97.83%;
                    letter-spacing: -0.03em;
                    color: #292930;
                }
            }

            .bottom {
                margin-top: 1%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                    font-family: Poppins, sans-serif;
                    font-weight: 600;
                    font-size: 1.75rem;
                    line-height: 97.83%;
                    letter-spacing: -0.03em;
                }

                .memorandum {
                    font-family: DM Sans, sans-serif;
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 99.33%;

                    letter-spacing: -0.025em;

                    color: #999FAE;

                    .social-icon {
                        margin-top: 1rem;
                        margin-bottom: 0.25rem;
                        width: 32px;
                        margin-right: 0.75rem;
                    }

                    .legal-info {
                        div {
                            padding-bottom: 3px;
                        }
                    }
                }

                .links {
                    display: flex;
                    align-self: start;
                    max-width: 50%;

                    .tos-cookies {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;
                    }

                    a {
                        margin-top: 0.5rem;
                        white-space: nowrap;
                        margin-right: 1rem;
                    }
                }

                a,
                .go-to-top {
                    cursor: pointer;
                    font-family: DM Sans, sans-serif;
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 99.33%;
                    letter-spacing: -0.025em;
                    color: #5956E9;
                }
            }
        }
    }

    @media($mobileBreak) {
        .footer {
            .footer-content {
                padding-top: 2rem;

                .top {
                    align-items: flex-start;
                    flex-direction: column-reverse;
                    padding-bottom: .75rem;

                    .monri-payweb {
                        margin-bottom: 3rem;
                        justify-content: center;
                        flex-wrap: wrap;
                    }
                }

                .bottom {
                    flex-direction: column;
                    margin-top: 1rem;

                    .tos-cookies {
                        margin-top: 1.5rem;

                        a {
                            font-size: 1.2rem;
                            margin-top: 0.5rem;
                            margin-bottom: 0.5rem;
                        }
                    }

                    a,
                    .go-to-top {
                        margin-top: .75rem;
                        align-self: start;
                    }
                }
            }
        }
    }

}

@media($mobileBreak) {
    .root-layout {
        overflow-x: hidden !important;
        position: fixed;
    }
}
</style>
