<template>
    <div class="profile">
        <vue-element-loading :active="async">
            <spinner/>
        </vue-element-loading>
        <div class="title-holder">
            <span class="title">{{ user|mergeName }}</span>
        </div>

        <div class="profile-form">
            <input-label :required="true" :error="errors.first_name" label="Ime">
                <input-field v-model="form.first_name" placeholder="Ime"/>
            </input-label>

            <input-label :required="true" :error="errors.last_name" label="Prezime">
                <input-field v-model="form.last_name" placeholder="Prezime"/>
            </input-label>

            <input-label label="Telefon">
                <input-field v-model="form.phone" placeholder="Telefon"/>
            </input-label>

            <input-label :required="true" :error="errors.email" label="Email">
                <input-field v-model="form.email" placeholder="Email"/>
            </input-label>

            <input-label label="Password">
                <input-field v-model="form.password" type="password" autocomplete="new-password"/>
            </input-label>

            <div class="general-error">
                <div class="error" v-for="error in errors.general" :key="error">
                    {{ error }}
                </div>
            </div>

            <div class="button-container">
                <Button class="save-button" @click="submit" :loading="async">
                    Snimi promjene
                </Button>
                <Button class="logout-button" @click="logout">
                    Odjava
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import InputField from '@/components/Ui/InputField/InputField';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import Button from '@/components/Ui/Button/Button';
import { difference } from '@/utils/utils';
import { cloneDeep } from 'lodash';
import Spinner from '@/components/Ui/Spinner/Spinner';
import { CHANGE_USER_PASSWORD_ACTION, EDIT_USER_ACTION, GET_USER_BY_ID_ACTION, SET_USER } from '@/store/actions/login';
import { STORAGE_KEYS } from '@/utils/consts';
import { RouteLink } from '@/store/modules/menu';

const defaultForm = {
    name: '',
    address: '',
    phone: '',
    email: '',
    password: '',
};

const transformFormFields = (form) => {

    return form;
};

export default {
    name: 'Profile',
    components: { Spinner, Button, InputLabel, InputField },
    data() {
        return {
            form: cloneDeep(defaultForm),
            errors: {
                general: [],
            },
        };
    },
    computed: {
        ...mapGetters([
            'userAsync',
            'loginAsync',
            'user',
            'userCoins',
        ]),
        async() {
            return this.userAsync || this.loginAsync;
        },
    },
    methods: {
        ...mapMutations({
            setUser: SET_USER,
        }),
        ...mapActions({
            editUser: EDIT_USER_ACTION,
            changeUserPasswordAction: CHANGE_USER_PASSWORD_ACTION,
            getUserById: GET_USER_BY_ID_ACTION,
        }),
        logout() {
            localStorage.removeItem(STORAGE_KEYS.TOKEN);
            localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
            this.setUser(null);
            this.$router.replace(RouteLink.Home);
        },
        setData() {
            if (this.user) {
                this.form = transformFormFields(cloneDeep(this.user));
            }
        },
        validateFields() {
            const errors = {
                general: [],
            };

            if (!this.form.first_name) {
                errors.first_name = 'Ime je obavezno';
            }

            if (!this.form.last_name) {
                errors.last_name = 'Prezime je obavezno';
            }

            if (!this.form.email) {
                errors.email = 'Email je obavezan';
            }

            this.errors = errors;

            return Object.keys(errors).length === 1;
        },
        async submit() {
            if (!this.validateFields()) return;

            const changed = difference(this.form, this.user);

            if (changed.password !== undefined) {
                await this.changeUserPasswordAction({ userId: this.user.id, password: changed.password });
                delete changed.password;
            }

            delete changed.roles;

            try {
                await this.editUser({ id: this.user.id, data: changed });
            } catch (ex) {
                console.error('error editing user');
            }
        },
    },
    watch: {
        user: function () {
            this.setData();
        },
    },
    async beforeMount() {
        const { params } = this.$route;

        if (!this.user) {
            if (params.id) {
                await this.getUserById({ userId: params.id });
            }
        }

        this.setData();
    },
};
</script>

<style lang="scss">
.profile {
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    padding-left: 7%;
    padding-right: 7%;
    min-height: 100vh;
    z-index: 5;
    width: 100%;
    flex-shrink: 0;

    .title-holder {
        .title {
            font-family: DM Sans, sans-serif;
            font-weight: 500;
            font-size: 4rem;
            line-height: 100%;
            letter-spacing: -0.045em;
            color: #292930;
        }
    }

    .profile-form {
        display: flex;
        flex-direction: column;
        padding-top: 1rem;
        width: 40%;
        padding-bottom: 4rem;
        box-sizing: border-box;

        .labeled-input {
            padding-bottom: 2rem;
            margin-bottom: 0;
        }

        .spinner-container {
            width: 50%;
        }

        .container {
            padding: 16px;
        }

        .general-error {
            .error {
                color: indianred;
                font-size: 14px;
            }
        }

        .button-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: row;

            .logout-button {
                background: indianred;
            }
        }
    }


    @media($mobileBreak) {
        .profile-form {
            width: 100%;
        }
    }
}
</style>
