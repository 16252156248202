import { apiCall, coins_packages } from '@/api/apiCall';
import { progressFetch } from '@/api/progressFetch';

export async function fetchCoinsPackagesPage(page = 1, perPage = 10) {
    let url = new URL(coins_packages);

    url.searchParams.append('page', page);
    url.searchParams.append('per_page', perPage);

    return apiCall({
        url: url.href,
        headers: {
            accept: 'application/ld+json',
        },
    })
        .then(r => r.json())
        .then(packages => {
            packages['hydra:member'] = packages['hydra:member'].filter(p => !p.is_apple_only);
            return packages;
        });
}

export async function editCoinsPackage(coinsPackageId, data) {
    return apiCall({
        url: coins_packages + `/${coinsPackageId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function addCoinsPackage(data) {
    return apiCall({
        url: coins_packages,
        method: 'POST',
        jsonData: data,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}

export async function deleteCoinsPackage(coinsPackageId) {
    return apiCall({
        url: coins_packages + `/${coinsPackageId}`,
        method: 'DELETE',
    })
        .then(() => {
            // showSuccessSwal();
        });
}

export async function changeCoinsPackageImage(coinsPackageId, image, abortToken, onProgressChange) {
    let url = new URL(`${coins_packages}/${coinsPackageId}/change_image`);

    const formData = new FormData();

    formData.append('file', image.file);

    try {
        return await progressFetch({
            url: url.href,
            method: 'POST',
            body: formData,
            signal: abortToken.signal,
            uploadProgress: onProgressChange,
        }).then(r => r.json());
    } catch (ex) {
        console.error('error uploading image');
        throw ex;
    }
}

export async function fetchCoinsPackageById(coinsPackageId) {
    return apiCall({
        url: `${coins_packages}/${coinsPackageId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}
