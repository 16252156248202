<template>
    <div class="home-page">
        <div class="frontpage">
            <div class="text">
                <div class="title">Tvornica<br/>snova</div>
                <div class="description">
                    Vaša jedinstvena<br/>
                    elektronska knjižara<br/>
                    <span style="color: #FF8F66; font-weight: 600">uvijek uz Vas!</span>
                </div>
                <button @click="openGooglePlay" class="get-on-play">
                    <img src="../../assets/imgs/home/playstore_logo.png"/>
                    <div class="text">
                        Preuzmi aplikaciju na<br/>
                        <span class="play-text">Google Play</span>
                    </div>
                </button>
                <button @click="openAppStore" class="get-on-app-store">
                    <img src="../../assets/imgs/home/app_store_logo.png"/>
                    <div class="text">
                        Preuzmi aplikaciju na<br/>
                        <span class="app-store-text">App Store</span>
                    </div>
                </button>
            </div>

            <div class="phone-img" v-if="!isMobile">
                <video-player :options="videoOptions2"/>
            </div>
        </div>


        <div class="phone-img-mobile" style="display: flex; align-items: center; justify-content: center;"
             v-if="isMobile">
            <video-player :options="videoOptions3"/>
        </div>
    </div>
</template>

<script>
import { checkIfMobile } from '@/utils/utils';
import VideoPlayer from '@/components/VideoPlayer';
import TvornicaVideoMain from '../../assets/ts_desktop_ok.mp4';
import TvornicaVideoMainMobile from '../../assets/ts_mobile_ok.mp4';

export default {
    name: 'Home',
    components: {
        VideoPlayer,
    },
    data() {
        return {
            videoOptions2: {
                autoplay: true,
                fluid: false,
                muted: true,
                loop: true,
                controls: false,
                sources: [
                    {
                        src: TvornicaVideoMain,
                        type: 'video/mp4',
                    },
                ],
            },
            videoOptions3: {
                autoplay: true,
                fluid: false,
                muted: true,
                loop: true,
                controls: false,
                sources: [
                    {
                        src: TvornicaVideoMainMobile,
                        type: 'video/mp4',
                    },
                ],
            },
        };
    },
    methods: {
        openGooglePlay() {
            window.open('https://play.google.com/store/apps/details?id=com.tvornicasnova.app', '_blank');
        },
        openAppStore() {
            window.open('https://apps.apple.com/us/app/tvornica-snova/id1571283497', '_blank');
        },
    },
    computed: {
        isMobile() {
            return window.matchMedia('max-width: 480px') && checkIfMobile();
        },
    },
};
</script>

<style scoped lang="scss">
.home-page {
    width: 100%;
    display: flex;
    flex-direction: column;

    .frontpage {
        position: relative;
        z-index: 5;
        min-height: 100vh;
        flex-shrink: 0;
        padding-top: 6rem;
        display: flex;
        flex-direction: row;
        padding-left: 7%;
        padding-right: 7%;
        justify-content: space-between;
        align-items: center;

        .get-on-app-store,
        .get-on-play {
            margin-top: 2rem;
            display: flex;
            border: none;
            background: #5956E9;
            padding: 1.5rem 2.4rem;
            border-radius: 20px;
            transition: all 200ms ease-in-out;
            cursor: pointer;
            align-items: center;

            &:focus,
            &:active,
            &:hover {
                outline: none;
                box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
            }

            img {
                height: 3.5rem;
                image-rendering: -webkit-optimize-contrast;
            }

            .text {
                margin-left: 0.5rem;
                text-align: start;
                font-family: Poppins, sans-serif;
                font-weight: 500;
                font-size: 1rem;
                color: white;

                .app-store-text,
                .play-text {
                    font-size: 1.5rem;
                }
            }
        }

        .text {
            .title {
                font-family: DM Sans, sans-serif;
                font-weight: bold;
                font-size: 6rem;
                line-height: 99.33%;
                /* or 95px */

                letter-spacing: -0.025em;

                color: #292930;

                .title-dot {
                    color: #5956E9;
                }
            }

            .description {
                padding-top: 44px;
                font-family: Poppins, sans-serif;
                font-size: 2rem;
                line-height: 2.5rem;

                color: #42424A;
            }
        }

        .phone-img {
            img {
                // ovo works, samo storm ne konta
                image-rendering: -webkit-optimize-contrast;
                width: 21rem;
            }
        }
    }

    .phone-img-mobile {
        position: relative;

        .blue-bg {
            bottom: 0;
            height: 50%;
            left: 0;
            right: 0;
            position: absolute;
            background: rgba(89, 86, 233, 0.15);
        }

        img {
            z-index: 5;
            position: relative;
            padding-left: 7%;
            padding-right: 7%;

            width: 100%;
        }
    }

    .secondpage {
        padding-top: 5rem;
        min-height: 71vh;
        position: relative;
        background: rgba(89, 86, 233, 0.15);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .secondpage-content {
            padding-left: 7%;
            padding-right: 7%;
            position: relative;
            z-index: 4;

            .subtitle {
                font-family: DM Sans, sans-serif;
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 99.33%;
                letter-spacing: -0.025em;
                color: #5956E9;
                padding-bottom: 2.1875rem;
            }

            .title {
                font-family: DM Sans, sans-serif;
                font-weight: 500;
                font-size: 3.7rem;
                letter-spacing: -0.025em;
                color: #292930;
                padding-bottom: 3.1875rem;
                position: relative;
                z-index: 5;
            }

            .description {
                font-family: DM Sans, sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 2.5rem;
                letter-spacing: -0.045em;
                color: #292930;
                padding-bottom: 2.1875rem;
            }

            .description2 {
                font-family: Poppins, sans-serif;
                font-size: 1rem;
                line-height: 1.5rem;
                color: #42424A;
                padding-bottom: 5rem;
            }
        }
    }


    .thirdpage {
        min-height: 58vh;
        position: relative;
        display: flex;
        justify-content: center;
        padding-right: 7%;

        .video-js-player {
            flex: 1;
            display: flex;
            align-items: center;
        }

        .thirdpage-content {
            width: 65%;
            padding-left: 7%;
            padding-right: 7%;
            position: relative;
            z-index: 4;

            .subtitle {
                font-family: DM Sans, sans-serif;
                font-weight: bold;
                font-size: 1.6rem;
                line-height: 99.33%;
                letter-spacing: -0.025em;
                color: #5956E9;
                padding-bottom: 2.1875rem;
            }

            .title {
                font-family: DM Sans, sans-serif;
                font-weight: 500;
                font-size: 3.7rem;
                letter-spacing: -0.025em;
                color: #292930;
                padding-bottom: 3.1875rem;
                position: relative;
                z-index: 5;
            }

            .description {
                font-family: DM Sans, sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 1.5rem;
                line-height: 2.5rem;
                letter-spacing: -0.045em;
                color: #292930;
                padding-bottom: 2.1875rem;
            }

            .description2 {
                font-family: Poppins, sans-serif;
                font-size: 1rem;
                line-height: 1.5rem;
                color: #42424A;
            }
        }
    }

    @media($mobileBreak) {
        .frontpage {
            flex-direction: column;
            padding-top: 17rem;
            min-height: 80vh;

            .get-on-app-store,
            .get-on-play {
                width: 100%;
                margin-bottom: 2rem;

                .text {
                    font-size: 1.4rem;

                    .app-store-text,
                    .play-text {
                        font-size: 2rem;
                    }
                }
            }

            .text {
                padding: 0;
                width: 100%;

                .description {
                    font-size: 2.6rem;
                    line-height: 3.2rem;
                }
            }


            .phone-img {
                padding-top: 3rem;

                img {
                    width: 100%;
                }
            }
        }

        .secondpage {
            min-height: unset;
            padding-top: 4rem;
            padding-bottom: 4rem;

            .secondpage-content {

                .subtitle {
                    font-size: 2rem;
                    line-height: 2.5rem;
                }

                .title {
                    font-size: 4.2rem;
                    line-height: 110%;
                }

                .description {
                    font-size: 2rem;
                    line-height: 2.75rem;
                }

                .description2 {
                    font-size: 1.45rem;
                    line-height: 2.1rem;
                }
            }
        }

        .thirdpage {
            min-height: unset;
            padding-top: 4rem;
            padding-bottom: 4rem;
            flex-direction: column;
            padding-right: unset;

            .thirdpage-content {
                width: unset;

                .subtitle {
                    font-size: 2rem;
                    line-height: 2.5rem;
                }

                .title {
                    font-size: 4.2rem;
                    line-height: 110%;
                }

                .description {
                    font-size: 2rem;
                    line-height: 2.75rem;
                }

                .description2 {
                    font-size: 1.45rem;
                    line-height: 2.1rem;
                }
            }
        }
    }
}
</style>
