<template>
    <div class="header" v-if="!isMobile">
        <div class="main-content">
            <div class="left">
                <div v-for="route in routes" :key="route.title" @click="route.onClick"
                     :class="['route', route.isActive && 'active']">
                    {{ route.title }}
                    <div class="active-line"></div>
                </div>
            </div>
            <div class="right">
                <button class="login-account" @click="loginAccountClick">
                    <span v-if="isLoggedIn">Vaš Nalog</span>
                    <span v-else>Uloguj se</span>
                </button>
            </div>
        </div>
    </div>
    <div class="header" v-else>
        <div class="header-content">
            <button class="toggle-mobile-menu-btn" @click="toggleMenu">
                <i v-if="!isBack" class="icon-menu"/>
                <span class="material-icons" v-else>
                    arrow_back
                </span>
            </button>
            <div class="header-title">
                Tvornica snova
            </div>
            <div style="min-width: 42px"/>
        </div>

        <div @click.prevent.capture="" :class="['mobile-menu', mobileMenuOpen && 'open']">
            <div class="menu-header-content">
                <button class="toggle-mobile-menu-btn" @click="toggleMenu">
                    <i class="icon-close"/>
                </button>
                <div class="header-title">
                    Tvornica snova
                </div>
                <div style="min-width: 42px"/>
            </div>

            <div class="menu-content">
                <div v-for="route in routes" :key="route.title" @click="route.onClick"
                     :class="['route', route.isActive && 'active']">
                    {{ route.title }}
                    <div class="active-line"></div>
                </div>
                <button class="login-account" @click="loginAccountClick">
                    <span v-if="isLoggedIn">Vaš Nalog</span>
                    <span v-else>Uloguj se</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { checkIfMobile } from '@/utils/utils';
import { RouteLink } from '@/store/modules/menu';
import { mapGetters } from 'vuex';

export default {
    name: 'Header',
    data() {
        return {
            mobileMenuOpen: false,
        };
    },
    computed: {
        ...mapGetters([
            'isLoggedIn',
        ]),
        routes() {
            let b = [{
                title: 'Naslovna',
                onClick: this.goHome,
                isActive: this.activeRoute === RouteLink.Home,
            }, {
                title: 'Kontakt',
                onClick: this.goToContact,
                isActive: this.activeRoute === RouteLink.Contact,
            }, {
                title: 'Ambasadori',
                onClick: this.goToAmbassadors,
                isActive: this.activeRoute === RouteLink.Ambassadors,
            },
                this.isLoggedIn && {
                    title: 'Profil',
                    onClick: this.goToProfile,
                    isActive: this.activeRoute === RouteLink.Profile,
                }].filter(t => t);

            return b;
        },
        RouteLink() {
            return RouteLink;
        },
        isBack() {
            return this.$route.path === RouteLink.TermsOfService
                || this.$route.path === RouteLink.CookiesPolicy
                || this.$route.path === RouteLink.MonriTos;
        },
        isMobile() {
            return checkIfMobile();
        },
        activeRoute() {
            return this.$route.path;
        },
    },
    methods: {
        toggleMenu() {
            if (this.isBack) {
                this.$router.back();
                return;
            }

            this.mobileMenuOpen = !this.mobileMenuOpen;
        },

        loginAccountClick() {
            if (this.isLoggedIn) {
                this.$router.push(RouteLink.Profile).catch(() => {
                });
            } else {
                this.$router.push(RouteLink.Login).catch(() => {
                });
            }

            if (this.mobileMenuOpen) {
                this.mobileMenuOpen = false;
            }
        },

        goToStore() {
            this.$router.push(RouteLink.Store).catch(() => {
            });
            if (this.mobileMenuOpen) {
                this.mobileMenuOpen = false;
            }
        },

        goToProfile() {
            this.$router.push(RouteLink.Profile).catch(() => {
            });
            if (this.mobileMenuOpen) {
                this.mobileMenuOpen = false;
            }
        },

        goHome() {
            this.$router.push(RouteLink.Home).catch(() => {
            });
            if (this.mobileMenuOpen) {
                this.mobileMenuOpen = false;
            }
        },

        goToContact() {
            this.$router.push(RouteLink.Contact).catch(() => {
            });
            if (this.mobileMenuOpen) {
                this.mobileMenuOpen = false;
            }
        },

        goToAmbassadors() {
            this.$router.push(RouteLink.Ambassadors).catch(() => {
            });
            if (this.mobileMenuOpen) {
                this.mobileMenuOpen = false;
            }
        },
    },
};

</script>

<style scoped lang="scss">

.header {
    position: absolute;
    top: 1rem;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    transition: all 250ms ease-in-out;
    height: 4.5rem;
    z-index: 10;
    flex-direction: row;
    padding-left: 7%;
    padding-right: 7%;

    .main-content,
    .menu-content {
        .login-account {
            cursor: pointer;
            font-family: Poppins, sans-serif;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.5rem;
            height: 100%;

            text-align: center;
            letter-spacing: -0.04em;

            color: #FF8F66;
            background: transparent;
            border: 2px solid #FF8F66;
            box-sizing: border-box;
            border-radius: 1.25rem;
            min-width: 10rem;
            transition: all 200ms ease-in-out;


            &:focus,
            &:active,
            &:hover {
                outline: none;
                box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
                background: #5956E9;
                color: white;
                border-color: #5956E9;
            }

        }

        .route {
            font-family: Poppins, sans-serif;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.5rem;
            letter-spacing: -0.04em;
            transition: all 200ms ease-in-out;
            color: #999FAE;
            margin-right: 4rem;
            cursor: pointer;

            .active-line {
                opacity: 0;
                height: 2px;
                background: #5956E9;
                border-radius: 4px;
                transition: all 200ms ease-in-out;
            }

            &.active {
                color: #FF8F66;

                .active-line {
                    opacity: 1;
                }
            }

            &:hover {
                .active-line {
                    opacity: 0.4;
                }
            }
        }
    }

    .main-content {
        width: 100%;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;

        .left {
            display: flex;
            flex-direction: row;

        }

        .right {
            height: 100%;

        }
    }

    .menu-header-content,
    .header-content {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .toggle-mobile-menu-btn {
            background: transparent;
            border: none;
            outline: none;
            font-size: 2rem;
            min-width: 42px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0px;
        }

        .header-title {
            font-family: Poppins, sans-serif;
            font-weight: 600;
            font-size: 1.75rem;
            line-height: 97.83%;
            letter-spacing: -0.03em;
            color: #292930;
        }
    }

    .menu-header-content {
        position: absolute;
        top: 1rem;
        padding-left: 7%;
        padding-right: 7%;
        height: 4.5rem;

        .toggle-mobile-menu-btn {
            color: #FFFFFF;
        }

        .header-title {
            color: #FFFFFF;
        }

        .login-account {
            height: 4rem;
        }
    }

    .menu-content {
        margin-top: 8rem;
        width: 50%;
        height: 100%;


        .route {
            height: 4rem;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 1.75rem;
            flex-direction: column;
            width: fit-content;
            margin: auto;
            margin-top: 1rem;

            .active-line {
                margin-top: 0.5rem;
                width: 100%;
            }

            &.active {
                color: #FF8F66;
            }
        }

        .login-account {
            margin-top: 1rem;
            width: 100%;
            height: 4rem;
        }
    }

    @media($mobileBreak) {
        .login-account {
            font-size: 1.75rem !important;
        }
    }

    .mobile-menu {
        position: fixed;
        top: 0;
        left: -100%;
        width: 100vw;
        height: 100vh;
        background: #3E206D;
        transition: all 200ms ease-in-out;
        display: flex;
        align-items: center;
        flex-direction: column;

        &.open {
            left: 0;
        }
    }
}

</style>
