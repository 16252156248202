<template>
    <div :class="['input-container', focus && 'focus', icon && 'icon']">
        <i :class="[icon]" v-if="icon"/>
        <input :value="actualValue"
               :name="name"
               :autocomplete="autocomplete"
               :placeholder="placeholder"
               :disabled="disabled"
               :type="type"
               @input="$emit('input', $event.target.value)" @focusin="focus = true"
               @focusout="focus = false"
               v-bind="{...this.$attrs}"
               class="tvornica-snova"/>
    </div>
</template>

<script>

export default {
    name: "InputField",
    data() {
        return {
            focus: false,
        }
    },
    props: {
        name: String,
        icon: String,
        value: [String, Number],
        placeholder: String,
        type: String,
        disabled: Boolean,
        autocomplete: String,
        dateFormat: {
            type: String,
            default: 'YYYY-MM-DD'
        }
    },
    computed: {
        actualValue() {
            if (this.type === 'date') {
                return this.$moment(this.value).format(this.dateFormat);
            }

            return this.value;
        }
    }
}
</script>

<style scoped lang="scss">
.input-container {
    display: flex;
    padding: 0px 12px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    border-radius: 16px;
    transition: all 300ms ease-in-out;
    border: 1px solid #E3E6E9;
    color: #444;
    background: #FFFFFF;
    font-weight: 600;
    font-size: 0.875rem;

    &.focus,
    &:focus,
    &:active,
    &:hover {
        outline: none;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        border-color: transparent;
    }

    &.icon {
        input.tvornica-snova {
            padding-left: 8px;
        }
    }

    input.tvornica-snova {
        background: #FFFFFF;
        border: 1px solid transparent;
        width: 100%;
        height: 4.5rem;
        padding: 8px 0px;
        font-size: 1rem;

        &:disabled {
            cursor: not-allowed;
        }

        &:-webkit-autofill {
            border: 1px solid #ffffff;
            -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        &::placeholder {
            color: #999FAE;
        }

        &:focus,
        &:active,
        &:hover {
            outline: none;
        }
    }
}
</style>
