<template>
    <div class="page single-book">
        <Button @click="openGooglePlay" class="get-on-play">
            <img src="../../assets/imgs/home/playstore_logo.png"/>
            <div class="text">
                Preuzmi aplikaciju na<br/>
                <span class="play-text">Google Play</span>
            </div>
        </Button>
        <Button @click="openAppStore" class="get-on-app-store">
            <img src="../../assets/imgs/home/app_store_logo.png"/>
            <div class="text">
                Preuzmi aplikaciju na<br/>
                <span class="app-store-text">App Store</span>
            </div>
        </Button>
    </div>
</template>

<script>
import { getPublicBookData } from '@/api/book/book';
import { storage_url } from '@/api/apiCall';
import Button from '@/components/Ui/Button/Button';

export default {
    name: 'SingleBook',
    components: { Button },
    metaInfo() {
        return {
            meta: [{
                vmid: 'og:type',
                name: 'og:type',
                content: 'article',
            }, {
                vmid: 'og:title',
                name: 'og:title',
                content: this.title,
            }, {
                vmid: 'og:description',
                name: 'og:description',
                content: this.description,
            }, {
                vmid: 'og:image',
                name: 'og:image',
                content: this.image,
            }],
        };
    },
    methods: {
        openGooglePlay() {
            window.open('https://play.google.com/store/apps/details?id=com.tvornicasnova.app', '_blank');
        },
        openAppStore() {
            window.open('https://apps.apple.com/us/app/tvornica-snova/id1571283497', '_blank');
        }
    },
    data() {
        return {
            title: '',
            description: '',
            image: null,
        };
    },
    async mounted() {
        const { params: { id } } = this.$route;

        try {
            const book = await getPublicBookData(id);

            this.title = `${book.title} - ${book.author}`;
            this.description = book.description;
            this.image = `${storage_url}/${book.image.content_url}`;
        } catch (ex) {
            /**/
        }

        window.location.replace(`tvornicasnova://books/book/${id}`);
    },
};
</script>

<style scoped lang="scss">
.single-book {
    display: flex;
    align-items: center;
    justify-content: center;

    .get-on-app-store,
    .get-on-play {
        margin-top: 2rem;
        display: flex;
        border: none;
        background: #5956E9;
        padding: 1.5rem 2.4rem;
        border-radius: 20px;
        transition: all 200ms ease-in-out;
        cursor: pointer;
        align-items: center;

        &:focus,
        &:active,
        &:hover {
            outline: none;
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
        }

        img {
            height: 3.5rem;
            image-rendering: -webkit-optimize-contrast;
        }

        .text {
            margin-left: 0.5rem;
            text-align: start;
            font-family: Poppins, sans-serif;
            font-weight: 500;
            font-size: 1rem;
            color: white;

            .app-store-text,
            .play-text {
                font-size: 1.5rem;
            }
        }
    }

    @media($mobileBreak) {
        min-height: 80vh;

        .get-on-app-store,
        .get-on-play {
            width: 100%;
            margin-bottom: 2rem;

            .text {
                font-size: 1.4rem;

                .app-store-text,
                .play-text {
                    font-size: 2rem;
                }
            }
        }
    }
}
</style>
