// import router from '../router';
import { ERROR_MESSAGES, STORAGE_KEYS } from '@/utils/consts';
import { merge } from 'lodash';
import { showSwalToast } from '@/utils/utils';

// ENDPOINTS

export const endpoint = (process.env.VUE_APP_ENDPOINT || 'http://localhost:8000') + '/api';
export const storage_url = process.env.VUE_APP_STORAGE_URL || 'http://localhost:8001';

export const login = `${endpoint}/login`;
export const contact = `${endpoint}/public/contact_form`;
export const social_login_google = `${endpoint}/social_login/m/google`;
export const social_login_facebook = `${endpoint}/social_login/m/facebook`;
export const me = `${endpoint}/me`;
export const users = `${endpoint}/users`;
export const public_endpoint = `${endpoint}/public`;
export const coins_packages = `${endpoint}/coins_packages`
export const payment_requests = `${endpoint}/payment_requests`
export const stripeSession = `${endpoint}/store/buy/coins/stripe`
export const monriSession = `${endpoint}/store/buy/coins/monri`;
export const monriVerify = `${endpoint}/public/store/monri/verify`;
export const stripe_key = process.env.VUE_APP_STRIPE_KEY;
export const paymentDemo = `${endpoint}/store/buy/coins/demo`

export function handleErrors(response) {
    if (response.ok || response.status === 204) {
        return response;
    } else {
        throw response;
    }
}

export function uploadImage() {

}

export function apiCall(params = {
    url: '',
    noAuth: false,
}) {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);

    const defaultParams = {
        headers: {
            'content-type': 'application/json',
        },
        method: 'GET',
        body: null,
    };

    if (params.body) {
        delete defaultParams.headers['content-type'];
    }

    if (params.jsonData) {
        params.body = JSON.stringify(params.jsonData);
        delete params.jsonData;
    }

    if (token && params.noAuth !== true) {
        defaultParams.headers['Authorization'] = `Bearer ${token}`;
    }

    const actualParams = merge(defaultParams, params);

    return new Promise((resolve, reject) => {
        return fetch(params.url, actualParams)
            .then(handleErrors)
            .then(resolve)
            .catch(async ex => {
                if (ex.status === 401) {
                    if (ex.json) {
                        const message = await ex.json()
                            .then(data => {
                                return data.message;
                            });
                        if (message === 'Invalid credentials.') {
                            // this is login, propagate this error up
                            // showSwalToast(message);
                            reject(message);
                        } else if (message === 'Expired JWT Token') {
                            // we should probs refresh token here
                            const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);

                            if (refreshToken) {
                                // proceed
                                let data = new FormData();
                                data.append('refresh_token', refreshToken);
                                return fetch(endpoint + '/token/refresh', {
                                    method: 'post',
                                    body: data,
                                })
                                    .then(handleErrors)
                                    .then(r => r.json())
                                    .then(r => {
                                        console.log(r);
                                        localStorage.setItem(STORAGE_KEYS.TOKEN, r.token);
                                        localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, r.refresh_token);
                                        //redo api call
                                        apiCall(params)
                                            .then(resolve);
                                    })
                                    .catch(() => {
                                        logOut();
                                    });
                            } else {
                                logOut();
                            }
                        } else if (message === 'An authentication exception occurred.') {
                            // USER PROBABLY GOT DISABLED
                            // showSwalToast(message);
                            logOut();
                        }
                    }
                } else if (ex.status === 404) {
                    // showSwalToast(ex.url + '\n' + ex.statusText);
                } else {
                    if (ex.json) {
                        try {
                            await ex.json()
                                .then(data => {
                                    if (data.detail) {
                                        showSwalToast(ERROR_MESSAGES[data.detail] ?? data.detail);
                                    }
                                });
                        } catch (ex) {
                            // this is most likely an  internal server that returned html body.
                            // rip
                            reject("Desila se nepoznata greška");
                        }
                    }
                }

                reject(ex);
            });
    });
}

export function logOut() {
    localStorage.removeItem(STORAGE_KEYS.TOKEN);
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
    window.location.reload();
}
