<template>
    <div class="custom-table">
        <div class="table-header">
            <div v-if="select">
                <Checkbox v-model="selectAllActive" @input="$emit('selectAllChanged', $event)"/>
            </div>
            <div :style="{maxWidth: field.width || 'initial'}"
                 class="table-header-item"
                 @click="onHeaderFieldClick(field)"
                 :class="{[`table-header-item-${field.headerClass}`]: field.headerClass}" v-for="field in activeFields"
                 :key="field.name">

                {{ field.title }}

                <i class="sort-icon icon"
                   v-if="field.sort"
                   :class="{'disabled': !sortEnabled || sortFieldName !== field.name, 'icon-up': sortFieldName === field.name && sortDirection === SORT_DIRECTION.UP, 'icon-down': sortFieldName !== field.name || sortDirection === SORT_DIRECTION.DOWN}"/>

            </div>
        </div>

        <div class="table-data">
            <div :class="{'table-row': true, 'selected': selectedIds.includes(item.id)}" v-for="item in renderData"
                 @click.stop="$emit('row-click', item)"
                 :key="item.id">
                <div v-if="select">
                    <Checkbox :value="selectedIds.includes(item.id)" @input="$emit('selectItem', item.id)"/>
                </div>

                <div :style="{maxWidth: field.width || 'initial'}"
                     class="table-row-item"
                     @click.stop="field.enableClick && $emit('column-click', item)"
                     :class="{[`table-header-item-${field.rowClass}`]: field.rowClass, 'light-text': field.lightText, 'is-clickable': field.enableClick}"
                     v-for="field in activeFields"
                     :key="`${item.id}-${field.name}`">

                    <slot :name="field.slot"
                          v-if="Boolean(field.slot)"
                          v-bind:item="item"/>

                    <span v-else>
                        <template v-if="field.accessor">
                            {{ getDeepProp(item, field.accessor) }}
                        </template>
                        <template v-else>
                            {{ item[field.name] }}
                        </template>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { cloneDeep } from 'lodash';
import { getDeepProp } from '@/utils/utils';
import Checkbox from "@/components/Ui/Checkbox/Checkbox";

const SORT_DIRECTION = {
    UP: 'up',
    DOWN: 'down',
};

export default {
    name: 'CustomTable',
    components: { Checkbox },
    data() {
        return {
            sortedData: null,
            sortEnabled: false,
            sortField: null,
            sortDirection: null,
            selectAllActive: false,
        };
    },

    props: {
        select: Boolean,
        data: Array,
        fields: Array,
        selectedIds: {
            type: Array,
            default: () => [],
        },
    },

    watch: {
        data: function (val) {
            if (this.sortEnabled) {
                const sData = cloneDeep(val);

                sData.sort((a, b) => {
                    return this.sortField.sort(a, b, val);
                });

                this.sortedData = sData;
            }
        },

        sortDirection: function (val) {
            if (this.sortEnabled) {
                const sData = cloneDeep(this.data);

                sData.sort((a, b) => {
                    return this.sortField.sort(a, b, val);
                });

                this.sortedData = sData;
            }
        },
    },

    computed: {
        activeFields() {
            return (this.fields || []).filter(field => field.disabled !== undefined ? !field.disabled : true);
        },
        sortFieldName() {
            return this.sortField?.name || '';
        },
        renderData() {
            if (this.sortEnabled && this.sortedData !== null) {
                return this.sortedData;
            } else {
                return this.data;
            }
        },

        SORT_DIRECTION() {
            return SORT_DIRECTION;
        },
    },

    methods: {
        getDeepProp,
        onHeaderFieldClick(field) {
            if (field.sort) {
                this.sortEnabled = true;
                this.sortField = field;

                if (this.sortDirection !== null) {
                    if (this.sortDirection === SORT_DIRECTION.UP) {
                        this.sortDirection = SORT_DIRECTION.DOWN;
                    } else {
                        this.sortEnabled = false;
                        this.sortDirection = null;
                        this.sortField = null;
                        this.sortedData = null;
                    }
                } else {
                    this.sortDirection = SORT_DIRECTION.UP;
                }
            }
        },
        emitColumn(item) {
            console.log(item);
            this.$emit('column-click', item)
        }
    },
};
</script>
