import { SET_DRAWER_EXPANDED } from "@/store/actions/menu";

export const RouteLink = {
    Home: "/",
    SingleBook: "/book/:id",
    Contact: "/contact",
    Ambassadors: "/ambassadors",
    Profile: "/profile",
    Login: "/login",
    Settings: "/settings",
    InternalWidgets: "/internal-widgets",
    Store: "/store",
    MonriPayment: '/store/monri/pay',
    TermsOfService: "/uslovi-koristenja",
    CookiesPolicy: "/kolacici",
    PrivacyPolicy: "/privacy-policy",
    MonriTos: "/uslovi-monri",
    MonriCardPaymentSafety: "/monri-sigurnost-placanja-karticama",
    MonriPrivacyStatement: "/monri-privacy-statement",
    MonriShoppingTerms: "/monri-uslovi-kupovine",
    MonriCurrencyConversionStatement: "/monri-izjava-o-konverziji-valute",
}

export default {
    state: {
        drawerExpanded: true,
        groups: [
            {
                link: RouteLink.Profile,
                name: "Profil",
                icon: "dashboard",
            },
            {
                link: RouteLink.Store,
                name: "Trgovina",
                icon: "",
            },
            // {
            //     link: RouteLink.Settings,
            //     name: "Postavke",
            //     icon: "",
            // }
        ],
    },
    mutations: {
        [SET_DRAWER_EXPANDED](state, toggle) {
            state.drawerExpanded = toggle;
        }
    },
    actions: {},
    getters: {
        activeItem: (state) => (activeItemPath) => state.groups.map(g => g.items).flat().find(i => i.link === activeItemPath) || null,
        isDrawerExpanded: (state) => state.drawerExpanded,
    }
};
