<template>
    <div class="cookies-root text-page">
        <div style="text-align: center">
            <b>KORIŠTENJE INTERNET KOLAČIĆA (COOKIES), OSTALIH TEHNOLOGIJA I TREĆIH LICA ZA PRIKUPLJANJE PODATAKA</b>
        </div>
        <p class="text-page-text">
            Internet kolačić je mala datoteka koju web stranica ili email poruka šalje vašem internet čitaču, koji ga
            onda
            može sačuvati na vašem hard disku. Naša web stranica i email poruke koriste internet kolačiće kako bismo
            maksimalno personalizovali korisničko iskustvo na našoj web stranici. Kolačić simulira neprekidnu konekciju.
            Oni
            omogućavaju čuvanje informacija o vašim podešavanjima i sesijama i dozvoljava pristup web stranicama bez
            ponovne
            personalizacije.
        </p>
        <p class="text-page-text">
            Web čitač (browser) se može podesiti da ne prihvata Internet kolačiće. U tom slučaju ćete biti u mogućnosti
            da
            gledate našu web stranicu, ali nećete moći da kreirate korisnički nalog ili da napravite transakciju.
        </p>
        <p class="text-page-text">
            Naš web server automatski sakuplja informacije kao što su adresa (URL) web stranice sa koje ste došli, koje
            stranice ste posjetili na našoj Internet prezentaciji, koji web čitač koristite za pregled naše web stranice
            i
            ostale statističke podatke koji nam pomažu da shvatimo i razumijemo kako posjetioci koriste naše web
            stranice.
        </p>
        <p class="text-page-text">
            Web stranica Tvornice snova koristi servise koje obezbjeđuju treća lica i koja u naše ime, putem interneta,
            analiziraju saobraćaj i posjećenost iste. Na taj način mogu biti prikupljane anonimne informacije o vašim
            posjetama našoj web stranici i vašoj interakciji sa našim oglašavanjima. Za te potrebe se mogu koristiti
            tehnologije slične onima koje smo opisali u prethodnom tekstu, a kako bi se izmjerila efikasnost oglašavanja
            i
            emailova.
        </p>
    </div>
</template>

<script>
export default {
    name: 'CookiesPolicy',
    beforeMount() {
        window.scrollTo(0, 0);
    },
};
</script>

<style scoped lang="scss">
.cookies-root {
    margin-top: 10rem;
    padding-top: 1rem;
    padding-left: 7%;
    padding-right: 7%;
    position: relative;
    z-index: 10000;

}
</style>
