import { apiCall, endpoint, monriVerify } from '@/api/apiCall';

export function startMonriSession(bookId, promoCode) {
    const jsonData = {};

    if (promoCode) {
        jsonData.promoCodes = [promoCode];
    }

    return apiCall({
        url: `${endpoint}/store/buy/book/${bookId}/monri`,
        method: 'POST',
        jsonData,
    })
        .then(r => {
            return r.json();
        });

}

export function verifyMonriTransaction(order_number, status) {
    return apiCall({
        url: monriVerify,
        method: 'POST',
        jsonData: {
            order_number,
            status,
        },
    })
        .then(r => {
            return r.json();
        });

}
