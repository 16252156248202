<template>
    <div class="monri-payment-container" v-if="showSuccessView !== true">
        <div class="transactionData">
            <input-label :required="true" :error="formErrors.fullName" label="Ime i Prezime">
                <input-field v-model="transactionDataForm.fullName"
                             autocomplete="name" name="fullname"
                             placeholder="Ime i Prezime"/>
            </input-label>

            <input-label :required="true" :error="formErrors.email" label="Email">
                <input-field v-model="transactionDataForm.email" placeholder="Email" autocomplete="email"/>
            </input-label>

            <input-label :required="true" :error="formErrors.address" label="Adresa">
                <input-field v-model="transactionDataForm.address" autocomplete="street-address" placeholder="Adresa"/>
            </input-label>

            <input-label :required="true" :error="formErrors.zip" label="Poštanski broj">
                <input-field v-model="transactionDataForm.zip"
                             inputmode="numeric"
                             autocomplete="postal-code"
                             placeholder="Poštanski broj"/>
            </input-label>

            <input-label :required="true" :error="formErrors.city" label="Grad">
                <input-field v-model="transactionDataForm.city" autocomplete="address-level2" placeholder="Grad"/>
            </input-label>

            <input-label :required="true" :error="formErrors.country" label="Država">
                <select-field accessor="code" v-model="transactionDataForm.country" label="name" :options="countries"
                              autocomplete="country" placeholder="Država"/>
            </input-label>

        </div>
        <form action="" method="post" id="payment-form"
              class="payment-form">
            <div class="form-row">
                <div id="card-element">
                    <!-- A Monri Component will be inserted here. -->
                </div>

                <!-- Used to display Component errors. -->
                <div id="card-errors" role="alert"></div>
            </div>

            <div class="errors">
                <div class="error" :key="error" v-for="error in errors">{{ error }}</div>
            </div>
        </form>

        <div class="terms-container">
            <Checkbox v-model="termsAccepted"/>
            <span>Slažem se sa <a href="/uslovi-monri">Uslovima Plaćanja</a>.</span>
        </div>

        <div class="submit-btn-container">
            <Button class="cancel-btn" @click="goBack">
                Odustani
            </Button>
            <Button :disabled="async || !termsAccepted"
                    :loading="async"
                    v-if="!monriLoading"
                    @click="submitPayment">
                {{ paymentButtonText }}
            </Button>
        </div>

        <div class="footer">
        </div>

        <div class="loader" v-if="monriLoading">
            <spinner/>
        </div>
    </div>
    <div class="monri-payment-container" v-else>
        <div class="success-text">
            <span>Uspješno ste izvršili kupovinu.</span><br/>
            <span>Biti ćete automatski redirektovani za 3 sekunde.</span>
        </div>
    </div>
</template>

<script>
import countriesArr from '@/assets/countries';
import { MONRI_AUTHENCITY_TOKEN, MONRI_SCRIPT, STORAGE_KEYS } from '@/utils/consts';
import { startMonriSession, verifyMonriTransaction } from '@/api/store/store';
import Button from '@/components/Ui/Button/Button';
import Spinner from '@/components/Ui/Spinner/Spinner';
import InputField from '@/components/Ui/InputField/InputField';
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import SelectField from '@/components/Ui/SelectField/SelectField';
import { cloneDeep } from 'lodash';
import mastercardLogo from '../../assets/imgs/logotypes/mastercard.png';
import maestroLogo from '../../assets/imgs/logotypes/maestro.png';
import visaLogo from '../../assets/imgs/logotypes/visa.gif';
import mastercardIdCheckLogo from '../../assets/imgs/logotypes/mastercard-idcheck.png';
import visaSecure from '../../assets/imgs/logotypes/visa-secure.jpg';
import unicreditMonri from '../../assets/imgs/logotypes/monri-unicredit.png';
import { mapGetters, mapMutations } from 'vuex';
import { UPDATE_USER } from '@/store/actions/login';
import Checkbox from '@/components/Ui/Checkbox/Checkbox';
import * as Sentry from "@sentry/vue";

export default {
    name: 'MonriPayment',
    components: { Checkbox, SelectField, InputLabel, InputField, Spinner, Button },

    data() {
        return {
            errors: [],
            formErrors: {},
            monriLoading: false,
            monri: null,
            card: null,
            async: false,
            isFlutter: false,
            transactionResult: null,
            showSuccessView: false,
            price: null,
            bookId: null,
            transactionDataForm: {
                fullName: null,
                email: null,
                address: null,
                city: null,
                zip: null,
                country: null,
            },
            termsAccepted: false,
        };
    },
    computed: {
        ...mapGetters({
            coinsPackage: 'selectedCoinsPackage',
        }),
        countries() {
            return countriesArr;
        },
        mastercardLogo() {
            return mastercardLogo;
        },
        maestroLogo() {
            return maestroLogo;
        },
        visaLogo() {
            return visaLogo;
        },
        mastercardIdCheckLogo() {
            return mastercardIdCheckLogo;
        },
        visaSecure() {
            return visaSecure;
        },
        unicreditMonri() {
            return unicreditMonri;
        },
        paymentButtonText() {
            return 'Plati ' + (this.price ? (this.price + '€') : '');
        },
    },
    mounted() {
        this.price = this.$route.query.price;
        this.bookId = this.$route.query.bookId;
        this.promoCode = this.$route.query.promoCode;
        this.isFlutter = this.$route.query.is_flutter;

        const userToken = this.$route.query.user_token;
        const userRefreshToken = this.$route.query.user_refresh_token;

        if (userToken && userRefreshToken) {
            localStorage.setItem(STORAGE_KEYS.TOKEN, userToken);
            localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, userRefreshToken);
        }

        this.loadMonri();
    },
    methods: {
        ...mapMutations({
            updateUser: UPDATE_USER,
        }),
        goBack() {
            this.$router.back();
        },
        async loadMonri() {
            this.monriLoading = true;
            await this.$loadScript(MONRI_SCRIPT);

            this.monri = window.Monri(MONRI_AUTHENCITY_TOKEN, { locale: 'hr' });

            try {
                const monriSession = await startMonriSession(this.bookId, this.promoCode);
                const { client_secret } = monriSession;
                const components = this.monri.components({ 'clientSecret': client_secret });

                this.card = components.create('card', { style: {} });
                this.card.mount('card-element');

                this.card.onChange((event) => {
                    let displayError = document.getElementById('card-errors');
                    if (event.error) {
                        displayError.textContent = event.error.message;
                    } else {
                        displayError.textContent = '';
                    }
                });

                this.$nextTick(() => {
                    this.monriLoading = false;
                });

            } catch (ex) {
                this.errors = ['Desila se greška. Pokušajte ponovo'];
                Sentry.captureMessage("ERROR: Failed to loadMonri()");
                // this.monriLoading = false;
            }
        },
        async handlePaymentResult(paymentResult) {
            // Handle PaymentResult
            if (paymentResult.status === 'approved') {
                try {
                    const updatedWallet = await verifyMonriTransaction(paymentResult.order_number, 'approved');

                    if (updatedWallet) {
                        this.updateUser(updatedWallet);
                    }

                    if (this.isFlutter) {
                        window.FlutterSuccess.postMessage('success');
                        this.showSuccessView = true;
                    } else {
                        this.showSuccessView = true;

                        setTimeout(() => {
                            this.$router.back();
                        }, 3000);
                    }
                } catch (ex) {
                    Sentry.captureException(ex);
                    this.errors = ['Desila se greška tokom transakcije. Molimo kontaktirajte podršku'];
                    Sentry.captureMessage("HandlePaymentResult error in status approved catch block");
                    this.async = false;
                }
            } else {
                await verifyMonriTransaction(paymentResult.order_number, 'declined');
                Sentry.captureMessage(`ERROR: Monri paymentResult.status is ${paymentResult.status} for order number ${paymentResult.order_number}`);
                this.errors = ['Desila se greška tokom transakcije. Molimo pokušajte ponovo'];

                if (paymentResult.errors) {
                    Sentry.captureMessage("Monri payment errors are", paymentResult.errors.join(","));
                    this.errors.concat(...paymentResult.errors);
                }
            }

            this.async = false;
        },

        verifyForm() {
            const formErrors = {};

            const { email, city, zip, address, country, fullName } = this.transactionDataForm;

            if (!email || email.length < 3) {
                formErrors.email = 'Minimalno tri karaktera';
            }
            if (!city) {
                formErrors.city = 'Minimalno tri karaktera';
            }
            if (!zip) {
                formErrors.zip = 'Minimalno tri karaktera';
            }
            if (!address) {
                formErrors.address = 'Minimalno tri karaktera';
            }
            if (!country) {
                formErrors.country = 'Država je obavezno polje';
            }
            if (!fullName) {
                formErrors.fullName = 'Minimalno tri karaktera';
            }

            this.formErrors = cloneDeep(formErrors);

            return Object.keys(formErrors).length === 0;
        },

        submitPayment() {
            if (!this.verifyForm()) {
                return;
            }

            this.async = true;
            const dataForm = cloneDeep(this.transactionDataForm);

            dataForm.phone = '000000000';
            dataForm.orderInfo = this.coinsPackageName;

            this.monri.confirmPayment(this.card, dataForm).then((result) => {
                if (result.error) {
                    let errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    this.async = false;
                } else {
                    this.handlePaymentResult(result.result);
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.monri-payment-container {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px 16px 40px 16px;

    .success-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
    }

    .back-container,
    .submit-btn-container,
    .transactionData,
    .success-container,
    .package-price,
    .terms-container,
    .payment-form {
        max-width: 400px;
        width: 100%;
    }

    .submit-btn-container {
        .cancel-btn {
            background: indianred;
        }
    }

    .package-price {
        margin-bottom: 8px;
        color: #929397;
        font-weight: 600;
    }

    .terms-container {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin: 20px 0;

        .checkbox-container {
            padding-left: 30px;
            margin-bottom: 20px;
        }

        a {
            text-decoration: none;
            color: $mainAccentColor;
            font-weight: 500;
        }
    }

    .submit-btn-container {
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: flex-end;

        :last-child {

            &:disabled {
                //color: $greyTextColor;
                opacity: 0.3;

                &:hover {
                    box-shadow: unset;
                    border-color: transparent;
                }
            }
        }

        button {
            margin-top: 0.5rem;
            width: 100%;
        }
    }

    .labeled-input {
        margin-bottom: 20px;
        margin-right: 0;

        &::v-deep .label {
            font-weight: 400;
            text-transform: uppercase;
            font-size: 10px;
            width: 100%;
            margin-bottom: 10px;
            letter-spacing: 1.5px;
            color: #929397;
            height: 10px;
            white-space: pre-wrap;
        }
    }

    .select-container {
        background: #fff;
        border-bottom: 1px solid #eee;
    }

    .input-container {
        background: #fff;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #eee;

        &:hover {
            border-bottom-color: #66afe9;
        }
    }

    #card-errors {
        font-size: 18px;
        color: indianred;
    }

    .errors {
        margin-bottom: 16px;

        .error {
            font-size: 14px;
            color: indianred;
        }
    }

    .footer {
        padding-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    @media ($mobileBreak) {
        .submit-btn-container {
            :first-child {
                display: none;
            }
        }
    }

}
</style>
