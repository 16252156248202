<template>
    <div class="stripe-callback">
        <vue-element-loading :active="async">
            <spinner/>
        </vue-element-loading>
        <span :class="{'status-message': true, 'error': this.success === false}">
            {{ statusMessage }}
        </span>
        <div class="coins-holder">
            <span class="package-title">Paket: </span>
            <span class="coins">{{ coinsPackage.coins || 0 }}</span>
            <span class="subtitle">Zlatnika</span>
            <span class="bonus" v-if="coinsPackage.bonus_percent">
                    (+{{ coinsPackage.bonus_percent + '%' }})
                </span>
        </div>
        <div class="payment-details">
            <img
                :src="coinsPackageImage"
                alt="No Image"/>
            <div class="name-and-price">
                <span class="name">{{ coinsPackage.name || 'N/A' }}</span>
                <span class="price">{{ coinsPackage|parseCoinsPackagePrice }} €</span>
            </div>
        </div>

        <div class="buttons">
            <Button v-if="!user" @click="$router.push('/login')">
                Prijavi se
            </Button>
            <Button v-if="isMobile" @click="goBackToApp" class="to-app-btn">
                Nazad u aplikaciju
            </Button>
        </div>
    </div>
</template>

<script>
import { apiCall, payment_requests } from "@/api/apiCall";
import Spinner from "@/components/Ui/Spinner/Spinner";
import Button from "@/components/Ui/Button/Button";
import { mapGetters } from "vuex";
import { checkIfMobile } from "@/utils/utils";
import { MEDIA_URL } from "@/utils/consts";

export default {
    name: 'StripeCallback',
    components: { Button, Spinner },
    data() {
        return {
            requestId: null,
            async: true,
            coinsPackage: {},
            success: null,
            placeholderImage: 'https://d1wqzb5bdbcre6.cloudfront.net/86b3495b299027a36286ad5cfb0d54b747571664/68747470733a2f2f66696c65732e7374726970652e636f6d2f66696c65732f665f746573745f454f4e63426e43434a6475527a59646f5451733947464c6d'
        };
    },
    computed: {
        ...mapGetters([
            'user'
        ]),
        coinsPackageImage() {
            return this.coinsPackage?.image ? (MEDIA_URL + this.coinsPackage?.image?.content_url) : this.placeholderImage;
        },
        statusMessage() {
            return this.success === null ? '' : this.success === true ? 'Uspješno ste dokupili zlatnike!' : 'Došlo je do greške prilikom kupovine zlatnika!'
        },
        isMobile() {
            return checkIfMobile();
        },
    },
    methods: {
        goBackToApp() {
            console.log('test')
        },
    },
    async mounted() {
        const requestId = this.$route.query.requestId;
        this.requestId = requestId;

        const paymentRequest = await apiCall({
            url: payment_requests + `/${requestId}`
        })
            .then(r => r.json())
            .catch(() => {
                this.async = false;
            });

        // Previously read book
        const previousBookId = this.$route.query?.previousBookId;

        console.log(previousBookId);

        this.success = paymentRequest?.status === 'success';

        if (paymentRequest) {
            this.coinsPackage = paymentRequest.coins_package;
        }

        this.async = false;
    },
};
</script>

<style scoped lang="scss">

.stripe-callback {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    flex: 1;

    .status-message {
        font-size: 18px;
        color: $purpleMainDark;

        &.error {
            color: indianred;
        }
    }

    .coins-holder {
        display: flex;
        margin: 10px 0;
        font-size: 18px;

        .package-title {
            color: $purpleMainDark;
            margin-right: 5px;
        }

        .coins {
            font-weight: bold;
            color: $purpleMainDark;
        }

        .subtitle {
            margin: 0 5px;
            color: $darkPurple;
            font-weight: bold;
        }

        .bonus {
            color: $mainOrange;
        }
    }

    .payment-details {
        display: flex;
        align-items: center;

        > img {
            border-radius: 4px;
            margin-right: 10px;
            width: 56px;
            height: 56px;
        }

        .name-and-price {
            display: flex;
            flex-direction: column;
            color: $purpleMainDark;

            .name {

                margin-bottom: 5px;
            }

            .price {

            }
        }
    }

    .buttons {
        margin: auto 20px 20px auto;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .to-app-btn {
            margin-left: 15px;
        }
    }
}

</style>
