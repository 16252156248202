<template>
    <div :class="['input-container', focus && 'focus', icon && 'icon']">
        <i :class="[icon]" v-if="icon"/>
        <textarea :value="value"
                  :rows="rows"
                  :placeholder="placeholder"
                  @input="$emit('input', $event.target.value)"
                  @focusin="focus = true"
                  @focusout="focus = false"
                  class="tvornica-snova"/>
    </div>
</template>

<script>

export default {
    name: "TextareaField",
    data() {
        return {
            focus: false,
        }
    },
    props: {
        icon: String,
        value: String,
        rows: {
            type: Number,
            default: 5
        },
        placeholder: String
    },
};
</script>

<style scoped lang="scss">
.input-container {
    display: flex;
    padding: 0px 12px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    border-radius: 16px;
    transition: all 300ms ease-in-out;
    border: 1px solid #E3E6E9;
    color: #444;
    background: #FFFFFF;
    font-weight: 600;
    font-size: 0.875rem;

    &.focus,
    &:focus,
    &:active,
    &:hover {
        outline: none;
        border-color: transparent;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }

    &.icon {
        input.tvornica-snova {
            padding-left: 8px;
        }
    }

    textarea.tvornica-snova {
        resize: none;
        border: 1px solid transparent;
        width: 100%;
        height: 100%;
        padding: 8px 0px;
        background: transparent;
        font-size: 1rem;

        &::placeholder {
            color: #999FAE;
        }

        &:focus,
        &:active,
        &:hover {
            outline: none;
        }
    }
}
</style>
