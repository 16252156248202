// import { doLogin, doMe } from '../../api/login/login';

import {
    DO_ME_ACTION,
    PERFORM_LOGIN_ACTION,
    PERFORM_LOGOUT_ACTION,
    SET_LOGIN_ASYNC,
    SET_LOGIN_ERRORS,
    CLEAR_STATE,
    SET_SIGN_UP_ERRORS,
    SET_USER, UPDATE_USER, EDIT_USER_ACTION, SET_USERS_ASYNC, CHANGE_USER_PASSWORD_ACTION, GET_USER_BY_ID_ACTION,
} from '@/store/actions/login';
import router from '../../router';
import { STORAGE_KEYS } from '@/utils/consts';
import {
    changeUserPassword,
    doLogin,
    doMe, editUser, fetchUserById,
} from '@/api/login/login';
import { getAccessLevel, isSuperAdmin } from '@/utils/utils';
import { PERFORM_FACEBOOK_LOGIN_ACTION, PERFORM_GOOGLE_LOGIN_ACTION } from '../actions/login';
import { socialLoginFacebook, socialLoginGoogle } from '../../api/login/login';

const getInitialState = () => ({
    user: null,
    loginAsync: false,
    userAsync: false,
    loginErrors: {},
    signupErrors: {},
});

export default {
    state: getInitialState(),
    mutations: {
        [SET_LOGIN_ASYNC](state, async) {
            state.loginAsync = async;
        },
        [SET_LOGIN_ERRORS](state, errors) {
            state.loginErrors = errors;
        },
        [SET_SIGN_UP_ERRORS](state, errors) {
            state.signupErrors = errors;
        },
        [SET_USER](state, user) {
            state.user = user;
        },
        [CLEAR_STATE](state) {
            Object.assign(state, getInitialState());
        },
        [UPDATE_USER](state, data) {
            Object.assign(state.user, data);
        },
        [SET_USERS_ASYNC](state, async) {
            state.userAsync = async;
        }
    },
    actions: {
        async [PERFORM_LOGIN_ACTION]({ commit }, { email, password }) {
            const errors = {};

            if (!email) {
                errors['email'] = 'Unesite email';
            }

            if (!password) {
                errors['password'] = 'Unesite šifru';
            }

            if (email && !email.includes('@') || email.length === 0) {
                errors['email'] = 'Unesite validan email.';
            }

            if (password && password.length < 8) {
                errors['password'] = 'Unesite validan password.';
            }

            if (Object.keys(errors).length > 0) {
                commit(SET_LOGIN_ERRORS, errors);
                return;
            }

            commit(SET_LOGIN_ASYNC, true);

            try {
                await doLogin(email, password);

                const me = await doMe();

                commit(SET_LOGIN_ERRORS, {});

                await commit(SET_USER, me);

                await router.push('/').catch(() => {
                });
            } catch (ex) {
                if (typeof ex === 'string') {
                    commit(SET_LOGIN_ERRORS, [ex]);
                } else {
                    commit(SET_LOGIN_ERRORS, ['Internal Error']);
                }
            } finally {
                commit(SET_LOGIN_ASYNC, false);
            }
        },
        async [PERFORM_GOOGLE_LOGIN_ACTION]({ commit }, { userInfo }) {
            const errors = {};

            if (Object.keys(errors).length > 0) {
                commit(SET_LOGIN_ERRORS, errors);
                return;
            }

            commit(SET_LOGIN_ASYNC, true);

            try {
                await socialLoginGoogle(userInfo);

                const me = await doMe();

                commit(SET_LOGIN_ERRORS, {});

                await commit(SET_USER, me);

                await router.push('/').catch(() => {
                });
            } catch (ex) {
                if (typeof ex === 'string') {
                    commit(SET_LOGIN_ERRORS, [ex]);
                } else {
                    commit(SET_LOGIN_ERRORS, ['Internal Error']);
                }
            } finally {
                commit(SET_LOGIN_ASYNC, false);
            }
        },
        async [PERFORM_FACEBOOK_LOGIN_ACTION]({ commit }, { userInfo }) {
            const errors = {};

            if (Object.keys(errors).length > 0) {
                commit(SET_LOGIN_ERRORS, errors);
                return;
            }

            commit(SET_LOGIN_ASYNC, true);

            try {
                await socialLoginFacebook(userInfo);

                const me = await doMe();

                commit(SET_LOGIN_ERRORS, {});

                await commit(SET_USER, me);

                await router.push('/').catch(() => {
                });
            } catch (ex) {
                if (typeof ex === 'string') {
                    commit(SET_LOGIN_ERRORS, [ex]);
                } else {
                    commit(SET_LOGIN_ERRORS, ['Internal Error']);
                }
            } finally {
                commit(SET_LOGIN_ASYNC, false);
            }
        },
        async [DO_ME_ACTION]({ commit }) {
            commit(SET_LOGIN_ASYNC, true);

            try {
                const me = await doMe();

                commit(SET_LOGIN_ERRORS, {});
                commit(SET_USER, me);
            } catch (ex) {
                if (typeof ex === 'string') {
                    commit(SET_LOGIN_ERRORS, [ex]);
                } else {
                    console.log(ex);
                    commit(SET_LOGIN_ERRORS, ['Internal Error']);
                }
            }

            commit(SET_LOGIN_ASYNC, false);
        },
        [PERFORM_LOGOUT_ACTION]({ commit }) {
            commit(CLEAR_STATE);
            localStorage.removeItem(STORAGE_KEYS.TOKEN);
            localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
            router.push('/login').catch(() => {
            });
        },
        async [EDIT_USER_ACTION]({ commit }, { id, data }) {
            commit(SET_USERS_ASYNC, true);
            try {
                const updatedUser = await editUser(id, data);
                commit(UPDATE_USER, updatedUser);
            } finally {
                commit(SET_USERS_ASYNC, false);
            }
        },
        async [CHANGE_USER_PASSWORD_ACTION]({ commit }, { userId, password }) {
            commit(SET_USERS_ASYNC, true);
            try {
                const updatedUser = await changeUserPassword(userId, password);
                commit(UPDATE_USER, updatedUser);
            } finally {
                commit(SET_USERS_ASYNC, false);
            }
        },
        async [GET_USER_BY_ID_ACTION]({ state, commit }, { userId }) {
            if (state.userAsync) return;

            commit(SET_USERS_ASYNC, true);
            const user = await fetchUserById(userId).catch(() => commit(SET_USERS_ASYNC, false));

            if (user) {
                commit(SET_USER, user);
            }

            commit(SET_USERS_ASYNC, false);
        }
    },
    getters: {
        userAccessLevel(state) {
            return getAccessLevel(state.user);
        },
        isSuperAdmin(state) {
            return isSuperAdmin(state.user);
        },
        isLoggedIn(state) {
            return state.user && localStorage.getItem(STORAGE_KEYS.TOKEN)
        },
        user(state) {
            return state.user;
        },
        loginAsync(state) {
            return state.loginAsync;
        },
        userAsync(state) {
            return state.userAsync;
        },
        userCoins(state) {
            return state?.user?.wallet?.total_coins ?? 0;
        }
    },
};
