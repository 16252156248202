<template>
    <div class="contact-page">
        <div class="contact-content">
            <div class="title">
                Kontaktirajte nas
            </div>
            <div class="enter-data">
                Unesite Vaše podatke
            </div>

            <div class="inputs">
                <input-label :required="true" :error="errors.name" label="Ime">
                    <input-field v-model="name" placeholder="Ime"/>
                </input-label>
                <input-label :required="true" :error="errors.email" label="Email">
                    <input-field v-model="email" placeholder="Email"/>
                </input-label>
                <input-label :required="true" :error="errors.message" label="Poruka">
                    <textarea-field v-model="message" placeholder="Poruka"/>
                </input-label>
                <vue-hcaptcha style="margin-top: 1rem; margin-bottom: 1rem" @verify="onCaptchaSolved"
                              sitekey="845db95c-5345-46e2-bcca-f1d28e8489e8"/>
                <div v-if="error" class="error">{{error}}</div>
                <Button :disabled="!solvedCaptcha || async" @click="sendMessage" :loading="async">
                    Pošaljite poruku
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import InputLabel from '@/components/Ui/InputLabel/InputLabel';
import InputField from '@/components/Ui/InputField/InputField';
import TextareaField from '@/components/Ui/TextareaField/TextareaField';
import Button from '@/components/Ui/Button/Button';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import { doContactForm } from '@/api/contact/contact';

export default {
    name: 'Contact',
    components: { VueHcaptcha, Button, TextareaField, InputField, InputLabel },
    data() {
        return {
            name: null,
            email: null,
            message: null,
            async: false,
            errors: {},
            error: null,
            solvedCaptcha: false,
        };
    },
    methods: {
        validateForm() {
            const retErrors = {};

            if (!this.name || this.name.split(' ').length < 2) {
                retErrors.name = 'Molimo unesite validno ime, npr: Niko Nikic';
                this.errors = retErrors;
                this.error = "Molimo ispravite greške";
                return false;
            }

            if (!this.email || !this.email.includes('@')) {
                retErrors.email = 'Molimo unesite validan E-Mail';
                this.errors = retErrors;
                this.error = "Molimo ispravite greške";
                return false;
            }

            if (!this.message || this.message.length === 0) {
                retErrors.message = 'Molimo unesite poruku';
                this.errors = retErrors;
                this.error = "Molimo ispravite greške";
                return false;
            }

            this.errors = retErrors;

            return true;
        },
        onCaptchaSolved() {
            this.solvedCaptcha = true;
        },
        async sendMessage() {
            if (!this.solvedCaptcha) return;
            if (!this.validateForm()) return;
            this.async = true;

            try {
                const r = await doContactForm({
                    name: this.name,
                    from: this.email,
                    message: this.message,
                });

                this.$swal("Vaša poruka je uspješno poslana. Odgovor će te dobiti na E-mail koji ste specifikovali iznad")

                console.log(r);
            } catch (ex) {
                console.error(ex);
            }

            this.async = false;
        },
    },
};
</script>

<style scoped lang="scss">
.contact-page {
    width: 100%;

    .contact-content {
        position: relative;
        z-index: 5;
        min-height: 100vh;
        padding-top: 8rem;
        display: flex;
        flex-direction: column;
        padding-left: 7%;
        padding-right: 7%;

        .title {
            font-family: DM Sans, sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 4rem;
            letter-spacing: -0.045em;
            color: #292930;
            padding-bottom: 1rem;

            .dot {
                color: #5956E9;
            }
        }

        .enter-data {
            font-family: Poppins, sans-serif;
            font-size: 1rem;
            line-height: 1.5rem;
            letter-spacing: -0.025em;
            color: #999FAE;
            padding-bottom: 1rem;
        }

        .inputs {
            width: 45%;

            .error {
                color: indianred;
                font-size: 1.1rem;
                margin-bottom: 1rem;
            }
        }
    }

    @media($mobileBreak) {

        ::v-deep .labeled-input {
            .label {
                font-size: 1.35rem;
            }
        }

        ::v-deep textarea {
            font-size: 1.35rem !important;
        }

        ::v-deep input {
            font-size: 1.35rem !important;
        }

        button {
            font-size: 1.35rem;
        }

        .contact-content {

            .enter-data {
                font-size: 1.5rem;
            }

            .inputs {
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }
    }
}
</style>
