<template>
    <div class="monri-page">
        <div class="monri-center"><b class="monri-title">USLOVI KUPOVINE</b><br/></div>
        <p class="monri-text">
            Ovim se uslovima utvrđuje postupak naručivanja, plaćanja, isporuke te reklamacija proizvoda koji su ponuđeni
            na Internet stranici www.tvornicasnova.com. Internet stranica tvornicasnova.com se može koristiti za Vašu
            privatnu upotrebu a prema sljedećim uslovima i pravilima. Trgovac je Gravis d.o.o., a kupac je posjetitelj
            stranice koji popuni elektronsku narudžbu, pošalje je trgovcu te izvrši plaćanje putem omogućenih vrsta
            kreditnih kartica.
        </p>
        <div class="monri-subtitle"><b>NARUČIVANJE</b><br/></div>
        <p class="monri-text">
            Kupac naručuje proizvod ili proizvode putem elektronskog obrasca narudžbe. Kupcem se smatra svaka osoba koja
            elektronski naruči barem jedan proizvod, popuni tražene podatke i uspješno zaključi narudžbu efektivnom
            online kupovinom. Sve cijene su izražene u Konvertibilnim markama, uključujući pripadajući PDV. Kupac
            dodatno ima mogućnost prikaza cijena u američkim dolarima ($) te eurima (EUR). Roba se naručuje elektronskim
            putem, pritiskom na određeni proizvod te za to predviđenom opcijom za kupovinu. Roba se smatra naručenom u
            trenutku kada kupac odabere i potvrdi način plaćanja te se uspješno realizuje sama transakcija. Kupac snosi
            odgovornost za tačnost i potpunost unesenih podataka prilikom naručivanja i kupovine.
        </p>
        <div class="monri-subtitle"><b>PLAĆANJE</b><br/></div>
        <p class="monri-text">Naručeni proizvodi ili usluge plaćaju se online jednom od kreditnih kartica: Mastercard,
            Maestro
            ili Visa.
        </p>
        <div class="monri-subtitle"><b>ISPORUKA</b><br/></div>
        <p class="monri-text">Isporuka je automatizovana te odmah po uspješnoj obradi kupovine, kupac ima mogućnost
            korištenja proizvoda kroz za to predviđenu opciju a koja je tada dostupna upravo prilikom ponovnog pregleda
            kupljenog proizvoda. Po kupovini, kupac je dužan provjeriti stanje proizvoda te u slučaju bilo kojih
            nepravilnosti odmah obavijestiti trgovca prema uputama u dijelu „Reklamacije“.
        </p>
        <div class="monri-subtitle"><b>REKLAMACIJE</b><br/></div>
        <p class="monri-text">Trgovac se obavezuje isporučiti proizvod koji je tehnički ispravan te odgovara opisu
            proizvoda navedenom na www.tvornicasnova.com. U slučaju tehničke neispravnosti elektronskog zapisa, trgovac
            preuzima potpunu odgovornost. Nastavno, kupac je dužan reklamirati proizvod odmah, tj. u roku od 24 sata po
            uspješnoj kupovini, i to na email adresu info@tvornicasnova.com. Nakon izvršene reklamacije po pitanju
            tehničke neispravnosti, trgovac je dužan otkloniti iste u najkraćem mogućem roku a najduže u roku od 5 (pet)
            radnih dana, odnosno, u slučaju nemogućnosti otklanjanja predmetnih tehničkih neispravnosti, izvršiti povrat
            novca putem za to predviđene procedure. Zamjenski proizvod nije moguć.
            U slučaju da kupac ne može preuzeti proizvod, a tehnički problemi nisu vezani za trgovca, već su uzrokovani
            od strane kupca, operatera, ili treće osobe, trgovac ne snosi nikakvu odgovornost. U ovom slučaju kupac se
            odriče bilo kakve reklamacije.
        </p>

        <div class="monri-subtitle"><b>ZAŠTITA AUTORSKIH PRAVA</b><br/></div>
        <p class="monri-text">
            Sva prava, u bilo kojoj formi, formatu i obimu, su zadržana. Izdavač je Gravis d.o.o. Kupac se obvezuje da
            neće neovlašteno umnožavati, izvoditi, koristi te preprodavati proizvode trgovca niti bilo koji dio tih
            proizvoda uopšte ili bez prethodne pismene saglasnosti izdavača.
        </p>

        <div class="monri-subtitle"><b>UOPŠTE</b><br/></div>
        <p class="monri-text">
            Gravis d.o.o. zadržava pravo na izmjenu ovih uslova i pravila. Sve promjene primijenit će se na korištenje
            www.tvornicasnova.com Internet stranice. Usluge koje Vam pruža www.tvornicasnova.com Internet prodajno
            mjesto ne uključuje troškove koje snosite koristeći računarsku opremu i usluge za pristup. Gravis d.o.o.
            nije odgovoran za troškove telefona, Internet prometa ili bilo koje druge troškove do kojih može doći. Iako
            Vam Gravis d.o.o. nastoji dati najbolju moguću ponudu usluga, Gravis d.o.o. ne može garantovati da će usluge
            na www.tvornicasnova.com odgovarati Vašim potrebama. Gravis d.o.o. također ne može garantovati da će usluge
            biti bez grešaka. Ukoliko eventualno dođe do greške, molimo Vas da je prijavite putem emaila
            info@tvornicasnova.com a kako bismo je otklonili u najkraćem mogućem roku.
        </p>
    </div>
</template>

<script>
export default {
    name: 'MonriPurchaseTerms',
};
</script>

<style lang="scss" scoped>
.monri-purchase-terms {

}
</style>
