import { apiCall, login, me, social_login_facebook, social_login_google, users } from '../apiCall';
import { STORAGE_KEYS } from '@/utils/consts';

export async function socialLoginGoogle(userInfo) {
    return apiCall({
        url: social_login_google,
        method: 'POST',
        noAuth: true,
        jsonData: {
            ...userInfo
        },
    })
        .then(r => r.json())
        .then(r => {
            // get token, set to storage, call /me, dispatch action
            localStorage.setItem(STORAGE_KEYS.TOKEN, r.token);
            localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, r.refresh_token);
        });
}

export async function socialLoginFacebook(userInfo) {
    return apiCall({
        url: social_login_facebook,
        method: 'POST',
        noAuth: true,
        jsonData: {
            ...userInfo
        },
    })
        .then(r => r.json())
        .then(r => {
            // get token, set to storage, call /me, dispatch action
            localStorage.setItem(STORAGE_KEYS.TOKEN, r.token);
            localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, r.refresh_token);
        });
}

export async function doLogin(email, password) {
    return apiCall({
        url: login,
        method: 'POST',
        noAuth: true,
        jsonData: {
            email,
            password,
        },
    })
        .then(r => r.json())
        .then(r => {
            // get token, set to storage, call /me, dispatch action
            localStorage.setItem(STORAGE_KEYS.TOKEN, r.token);
            localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, r.refresh_token);
        });
}

export async function doMe() {
    return apiCall({
        url: me,
        method: 'GET',
    })
        .then(r => r.json());
}

export async function editUser(userId, data) {
    return apiCall({
        url: users + `/${userId}`,
        method: 'PUT',
        jsonData: data,
    })
        .then(r => {
            return r.json();
        });
}

export async function changeUserPassword(userId, password) {
    return apiCall({
        url: users + `/${userId}/change_password`,
        method: 'PUT',
        jsonData: {
            password
        },
    })
        .then(r => {
            return r.json();
        });
}

export async function fetchUserById(userId) {
    return apiCall({
        url: `${users}/${userId}`,
    })
        .then(r => {
            // showSuccessSwal();
            return r.json();
        });
}
