<template>
    <div class="video-js-player">
        <video ref="videoPlayer" class="video-js vjs-big-play-centered"></video>

        <div class="video-overlay" @click="onVideoClick">
            <div class="mute-icon-container">
                <img class="mute-icon" :src="mutedIcon" v-if="this.player ? this.player.muted() : true"/>
                <img class="mute-icon" :src="unmutedIcon" v-else/>
            </div>
        </div>
    </div>
</template>

<script>
import videojs from 'video.js';
import muted from '@/assets/volume_off.svg';
import unmuted from '@/assets/volume_up.svg';

export default {
    name: 'VideoPlayer',
    props: {
        options: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            player: null,
            update: 0,
        };
    },
    computed: {
        mutedIcon() {
            return muted;
        },
        unmutedIcon() {
            return unmuted;
        },
    },
    methods: {
        onVideoClick() {
            this.$forceUpdate();

            if (!this.player.paused()) {
                console.log(this.player.muted());
                if (this.player.muted()) {
                    this.player.muted(false);
                } else {
                    this.player.muted(true);
                }
            }
        },
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options, () => {
            this.player.log('onPlayerReady', this);
            this.$forceUpdate();
        });
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
};
</script>

<style lang="scss">
.video-js-player {
    position: relative;

    .video-js {
        background-color: transparent;
    }

    .video-overlay {
        user-select: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .mute-icon-container {
            background-color: rgba(0, 0, 0, 0.9);
            border-radius: 100px;

            position: absolute;
            padding: 0.5rem;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;

            right: 1.5rem;
            top: 1.5rem;
        }

        .mute-icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    @media ($mobileBreak) {
        .video-overlay {
            .mute-icon-container {
                width: 4.5rem;
                height: 4.5rem;

                .mute-icon {
                    width: 2.25rem;
                    height: 2.25rem;
                }
            }
        }
    }
}
</style>
