<template>
    <button @click="$emit('click')"
            :disabled="loading || disabled"
            :class="['tvornica-snova', flat && 'flat', inverted && 'inverted']">
        <slot/>
        <div v-if="loading" class="button-async-container">
            <Spinner :invert="true" :small="true" :color="spinnerColor"/>
        </div>
    </button>
</template>

<script>

import Spinner from '@/components/Ui/Spinner/Spinner';

export default {
    name: 'Button',
    components: { Spinner },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        inverted: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        spinnerColor: String
    },
};

</script>

<style scoped lang="scss">
button.tvornica-snova {
    padding: 1rem 1.5rem;
    border: 1px solid transparent;
    background: $mainAccentColor;
    color: #fff;
    font-weight: 600;
    font-size: 0.875rem;
    border-radius: 20px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    box-shadow: $widgetShadow;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    &.flat {
        box-shadow: none;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &.inverted {
        background: #fff;
        border: 1px solid $mainAccentColor;
        color: $mainAccentColor;


        &:hover,
        &:focus {
            background: #efefef;
            border: 1px solid $mainAccentLightColor;
        }
    }

    .button-async-container {
        margin-left: 4px;
    }

    &:focus,
    &:hover {
        background: $mainAccentLightColor;
        border: 1px solid $mainAccentColor;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
}
</style>
