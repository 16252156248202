import { apiCall, contact } from '@/api/apiCall';

export async function doContactForm(data) {
    return apiCall({
        url: contact,
        method: 'POST',
        noAuth: true,
        jsonData: data,
    });
}
