<template>
    <div class="monri-page">
        <div class="monri-center monri-title"><b>IZJAVA O KONVERZIJI VALUTE</b><br/></div>
        <p class="monri-text">
            All payments will be affected in BAM currency. The amount your credit card account will be charged for is
            obtained through the conversion according to the current exchange rate of the local National bank. When
            charging your credit card, the same amount is converted into your local currency according to the exchange
            rate of credit card associations. As a result of this conversion there is a possibility of a slight
            difference from the original price stated in our web site.
            All payments will be affected in the currency of Bosnia and Herzegovina. The charged amount on your credit
            card account is converted into your local currency according to the exchange rate of credit card
            associations.
        </p>
    </div>
</template>

<script>
export default {
    name: 'MonriCurrencyConversionStatement',
};
</script>

<style lang="scss" scoped>

.monri-currency-conversion-statement {

}

</style>
