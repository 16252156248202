<template>
    <div class="login page">
        <div class="welcome-back">{{ $t('welcome_back') }}</div>
        <div class="sign-in-to-continue">{{ $t('sign_in_to_continue') }}</div>

        <div class="login-container">
            <div @click="googleLogin" class="social-login-btn google">
                <img src="../../assets/imgs/home/google_g.png"/>
                <div>
                    Prijavite se sa Google
                </div>
            </div>
            <div @click="facebookLogin" class="social-login-btn facebook">
                <img src="../../assets/imgs/home/facebook_f.png"/>
                <div>
                    Prijavite se sa Facebook
                </div>
            </div>

            <div class="inputs">

                <input-field v-model="email"
                             :disabled="async"
                             v-on:keyup.native.enter="submit"
                             name="email"
                             autofill="username"
                             :error="errors['email']"
                             :placeholder="$t('email')"
                             required
                             type="email"/>

                <input-field v-model="password"
                             :disabled="async"
                             v-on:keyup.native.enter="submit"
                             style="margin-top: 24px;"
                             autofill="password"
                             name="password"
                             :error="errors['password']"
                             :placeholder="$t('password')"
                             required
                             type="password"/>
            </div>
            <div v-for="error in errors" class="error" :key="error">
                <span>{{ error }}</span>
            </div>
            <div class="button-container">
                <Button :loading="async" class="login-btn" @click="submit">
                    {{ $t('login') }}
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import InputField from '@/components/Ui/InputField/InputField';
import { mapActions, mapMutations } from 'vuex';
import { PERFORM_LOGIN_ACTION, SET_LOGIN_ERRORS } from '@/store/actions/login';
import Button from '@/components/Ui/Button/Button';
import { PERFORM_FACEBOOK_LOGIN_ACTION, PERFORM_GOOGLE_LOGIN_ACTION } from '@/store/actions/login';

export default {
    name: 'Login',
    components: { Button, InputField },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            email: '',
            password: '',
        };
    },
    computed: {
        errors() {
            return this.$store.state.login.loginErrors;
        },

        async() {
            return this.$store.state.login.async;
        },
    },
    mounted() {
        this.initFirebase();
    },
    beforeDestroy() {
        this.setLoginErrors([]);
    },
    methods: {
        ...mapMutations({
            setLoginErrors: SET_LOGIN_ERRORS,
        }),
        ...mapActions({
            performLogin: PERFORM_LOGIN_ACTION,
            performGoogleLogin: PERFORM_GOOGLE_LOGIN_ACTION,
            performFacebookLogin: PERFORM_FACEBOOK_LOGIN_ACTION,
        }),
        async facebookLogin() {
            var provider = new window.firebase.auth.FacebookAuthProvider();

            try {
                const result = await window.firebase.auth().signInWithPopup(provider);
                const additionalUserInfo = result.additionalUserInfo;
                additionalUserInfo.profile.sub = additionalUserInfo.profile.id;

                this.performFacebookLogin({ userInfo: additionalUserInfo.profile });

            } catch (ex) {
                this.setLoginErrors([...this.errors, 'Desila se greška prilikom Google prijave']);
            }
        },
        async googleLogin() {
            const provider = new window.firebase.auth.GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/userinfo.email');

            try {
                const result = await window.firebase.auth().signInWithPopup(provider);

                const additionalUserInfo = result.additionalUserInfo;
                additionalUserInfo.profile.sub = additionalUserInfo.profile.id;

                this.performGoogleLogin({ userInfo: additionalUserInfo.profile });

            } catch (ex) {
                this.setLoginErrors([...this.errors, 'Desila se greška prilikom Google prijave']);
            }
        },
        initFirebase() {
            var firebaseConfig = {
                apiKey: 'AIzaSyCQpqMQYwId7jMK9NpjFWiqPu6R5DM7ke8',
                authDomain: 'tvornicasnova-458f8.firebaseapp.com',
                projectId: 'tvornicasnova-458f8',
                storageBucket: 'tvornicasnova-458f8.appspot.com',
                messagingSenderId: '846075198198',
                appId: '1:846075198198:web:f6cc2ebf4cb3afe2124ad7',
                measurementId: 'G-QC6M0MGK40',
            };
            if (!window.firebase.apps.length) {
                // Initialize Firebase
                window.firebase.initializeApp(firebaseConfig);
                window.firebase.analytics();
            }
        },
        submit() {
            if (this.async) return;

            this.performLogin({
                email: this.email,
                password: this.password,
            });
        },
    },

};
</script>

<style scoped lang="scss">

.login {
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    padding-left: 7%;
    padding-right: 7%;
    max-height: unset;

    .welcome-back {
        font-family: DM Sans, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 4rem;
        letter-spacing: -0.045em;
        color: #292930;
        padding-bottom: 1rem;

        .dot {
            color: #5956E9;
        }
    }

    .sign-in-to-continue {
        font-family: Poppins, sans-serif;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: -0.025em;
        color: #999FAE;
        padding-bottom: 1rem;
    }

    .social-login-btn {
        background: $pageBg;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        padding: 1rem 2rem;
        height: 4.5rem;
        border-radius: 20px;

        font-family: Poppins, sans-serif;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: center;
        letter-spacing: -0.04em;
        color: #2522BA;
        border: 1px solid #E3E6E9;

        div {
            margin-left: 1.5rem;
        }

        img {
            height: 2rem;
            image-rendering: -webkit-optimize-contrast;
        }
    }

    .facebook {
        background: #5956E9;
        color: #FFFFFF;
        margin-top: 1rem;
        margin-bottom: 3rem;

        img {
            padding-left: 0.4rem;
        }
    }


    .error {
        margin-top: 8px;
        font-size: 1.2rem;
        font-weight: 700;
        color: indianred;
    }

    .login-container {
        display: flex;
        flex-direction: column;
        z-index: 2;
        width: 40%;

        .welcome-back {
            font-size: 22px;
            font-weight: 600;
        }

        .sign-in-to-continue {
            font-size: 14px;
            margin-top: 12px;
            color: var(--secondary-color);
        }

        .inputs {
            margin-top: 10px;

            .outer-input-container {
                width: 22.5rem;
            }
        }

        .button-container {
            align-self: flex-start;
            margin-top: 1rem;

            .login-btn {
                height: 4.5rem;
                min-width: 10rem;
            }
        }
    }

    @media ($mobileBreak) {
        .login-container {
            width: 100%;
        }

        .sign-in-to-continue {
            font-size: 1.5rem;
        }

        .social-login-btn {
            font-size: 1.25rem;
        }

        .login-btn {
            font-size: 1.25rem;
        }

        ::v-deep input {
            font-size: 1.25rem !important;
        }

        .button-container {
            width: 100%;

            .login-btn {
                width: 100%;
            }
        }
    }
}
</style>

