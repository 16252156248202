<template>
    <div class="monri-page">
        <div class="monri-center"><b class="monri-title">IZJAVA O PRIVATNOSTI</b><br/><br/></div>
        <div class="monri-subtitle"><b>* * * * Izjava o zaštiti i prikupljanju ličnih podataka</b><br/><br/></div>
        <p class="monri-text">
            Obavezujemo se pružati zaštitu ličnim podacima kupaca, na način da prikupljamo samo nužne, osnovne podatke o
            kupcima / korisnicima koji su neophodni za ispunjenje naših obaveza; informišemo kupce o načinu korištenja
            prikupljenih podataka, redovno dajemo kupcima mogućnost izbora o upotrebi njihovih podataka, uključujući
            mogućnost odluke žele li ili ne da se njihovo ime ukloni sa lista koje se koriste za marketinške kampanje.
            Svi podaci o korisnicima se strogo čuvaju i dostupni su samo zaposlenima kojima su ti podaci nužni za
            obavljanje posla. Svi naši zaposleni i poslovni partneri odgovorni su za poštovanje načela zaštite
            privatnosti.
        </p>

        <div class="monri-subtitle"><b>* * * * Personal data gathering and protection statement</b><br/><br/></div>
        <p class="monri-text">
            We are committed to provide service of protection of our customer's personal data in a way that we collect
            only essential basic information about our buyers that are necessary for fulfilling our obligations. We also
            inform our customers about the way we collect information and regularly give customers an option about how
            their information will be used, including the possibility to decide whether their name should be included or
            omitted from the lists used for marketing campaigns. All user information are strictly guarded and are
            available only to the employees who need those information for completing the job. All our employees and
            business partners are responsible to follow the principles of confidentiality protection.
        </p>

    </div>
</template>

<script>
export default {
    name: 'MonriPrivacyStatement',
};
</script>

<style lang="scss" scoped>

</style>
