// actions
export const FETCH_ALL_COINS_PACKAGES_ACTION = 'FETCH_ALL_COINS_PACKAGES_ACTION';
export const FETCH_COINS_PACKAGES_PAGE_ACTION = 'FETCH_COINS_PACKAGES_PAGE_ACTION';
export const SEARCH_COINS_PACKAGES_ACTION = 'SEARCH_COINS_PACKAGES_ACTION';
export const ADD_COINS_PACKAGE_ACTION = 'ADD_COINS_PACKAGE_ACTION';
export const EDIT_COINS_PACKAGE_ACTION = 'EDIT_COINS_PACKAGE_ACTION';
export const DELETE_COINS_PACKAGE_ACTION = 'DELETE_COINS_PACKAGE_ACTION';
export const CHANGE_COINS_PACKAGE_IMAGE_ACTION = 'CHANGE_COINS_PACKAGE_IMAGE_ACTION';
export const GET_COINS_PACKAGE_BY_ID_ACTION = 'GET_COINS_PACKAGE_BY_ID_ACTION';

// mutations
export const SET_COINS_PACKAGES = 'SET_COINS_PACKAGES';
export const SELECT_COINS_PACKAGE = 'SELECT_COINS_PACKAGE';
export const SET_COINS_PACKAGES_ASYNC = 'SET_COINS_PACKAGES_ASYNC';
export const SET_SEARCH_COINS_PACKAGES = 'SET_SEARCH_COINS_PACKAGES';
export const SET_COINS_PACKAGES_SEARCH_TEXT = 'SET_COINS_PACKAGES_SEARCH_TEXT';
export const APPEND_COINS_PACKAGES_PAGE = 'APPEND_COINS_PACKAGES_PAGE';
export const SET_SEARCH_ASYNC = 'SET_SEARCH_ASYNC';
export const UPDATE_COINS_PACKAGE = 'UPDATE_COINS_PACKAGE';
export const ADD_NEW_COINS_PACKAGE = 'ADD_NEW_COINS_PACKAGE';
export const DELETE_COINS_PACKAGE = 'DELETE_COINS_PACKAGE';
export const SET_COINS_PACKAGES_CURRENT_PAGE = 'SET_COINS_PACKAGES_CURRENT_PAGE';
export const SET_TOTAL_PAGES = 'SET_TOTAL_PAGES';
export const SET_COINS_PACKAGES_PER_PAGE = 'SET_COINS_PACKAGES_PER_PAGE';
export const SET_COINS_PACKAGES_UPLOAD_ABORT_TOKEN = 'SET_COINS_PACKAGES_UPLOAD_ABORT_TOKEN';
export const SET_COINS_PACKAGES_UPLOAD_PROGRESS = 'SET_COINS_PACKAGES_UPLOAD_PROGRESS';
