<template>
    <div class="monri-page">
        <div class="monri-center"><b class="monri-title">SIGURNOST PLAĆANJA KREDITNIM KARTICAMA</b><br/><br/></div>
        <b class="monri-subtitle">* * * * Sigurnost plaćanja kreditnim karticama</b><br/><br/>
        <p class="monri-text">
            Tajnost Vaših podataka je zaštićena i osigurana upotrebom SSL enkripcije. Stranice za naplatu putem
            interneta osigurane su korištenjem Secure Socket Layer (SSL) protokola sa 128-bitnom enkripcijom podataka.
            SSL enkripcija je postupak šifriranja podataka radi sprječavanja neovlaštenog pristupa prilikom njihovog
            prenosa. Time je omogućen siguran prenos informacija te onemogućen nedozvoljen pristup podacima prilikom
            komunikacije između korisnikovog računara i WebPay servisa, te obratno. WebPay servis i finansijske ustanove
            razmjenjuju podatke upotrebom virtualne privatne mreže (VPN), koja je zaštićena od neautorizovanog pristupa.
            Monri Payment Gateway je sertifikovan prema PCI DSS Level 1 sigurnosnom standardu propisanom Visa i
            Mastercard pravilima. Trgovac ne pohranjuje brojeve kreditnih kartica i brojevi nisu dostupni neovlaštenim
            osobama. </p>
        <div class="monri-subtitle"><b> * * * Creditcard purchase security statement</b><br/></div>
        <p class="monri-text">
            Confidentiality of your information is protected and secured by using SSL encryption. Pages for web payment
            are secured by using Secure Socket Layer (SSL) protocol with 128-bit data encryption. SSL encryption is a
            data coding procedure for prevention of unauthorized access during data transfer. This enables a secure data
            transfer and prevents unauthorized data access during communication between user and Monri WebPay Payment
            Gateway and vice versa. Monri WebPay Payment Gateway and financial institutions exchange data by using their
            virtual private network (VPN) which is also protected from unauthorized access.
            Monri Payments is PCI DSS Level 1 certified payment service provider. Credit card numbers are not stored by
            Merchant and are not available to unauthorized personnel.
        </p>
    </div>
</template>

<script>
export default {
    name: 'MonriCardSafety',
};
</script>

<style lang="scss" scoped>
</style>
